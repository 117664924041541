import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/Episode292023HorrorRoundup.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the Bring Me The Axe 2023 horror movie roundup"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 29</h1>
                    <h2>2023 Roundup</h2>
                    <h3>December 18, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/2023-roundup">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:02.391)
                        Hey, you&#39;re listening to Bring Me the Axe. I&#39;m Brian White, and I&#39;m joined as always by my co-host and brother, Dave White. How you doing, Dave?</p>
                        <p>Dave! (00:09.014)
                        Oh, pretty good. Pretty good. Tell you a thing that happened to me today. I was out for a walk and walk through Harvard Square and turn to corner. I almost walked right smack into a fucking turkey. These things are a goddamn menace. Yeah. Oh, yeah. Yeah.</p>
                        <p>Bryan! (00:23.415)
                        Wait, a turkey in Harvard Square. That&#39;s a problem. Like I see them around here all the time, but I live in the fucking sticks. That&#39;s crazy.</p>
                        <p>Dave! (00:33.278)
                        Yep. I do not and they are a menace.</p>
                        <p>Bryan! (00:38.339)
                        terrifying. They&#39;re terrible. When I used to go running, I used to see them like they&#39;d be like on the side of the road. And like, they&#39;re terrifying. They&#39;re scary looking birds. I go on the other side of the road, and they would sit there and they would do this fucking thing where they drag their fucking talons across the cement. God damn fuck those things. I don&#39;t care for it.</p>
                        <p>Dave! (00:38.978)
                        Scared the shit out of me.</p>
                        <p>Dave! (00:53.346)
                        Yeah, they&#39;re threatening you. They&#39;re challenging you to a duel. I read a thing once that was like, you gotta put them in their place. Like you got, like, cause they&#39;ll like still get in the middle of the road and they&#39;ll just like fucking stand there and be a jerk. And the thing was like, sometimes you gotta just bump them with your car. They will move eventually. They don&#39;t want to die. And I&#39;m like, you know what? I&#39;m all for it. I don&#39;t have a car, but if I did, I would hit all of them. Because I hate them. Yeah, that&#39;s the thing that happened to me.</p>
                        <p>Bryan! (01:01.72)
                        You turn Adam</p>
                        <p>Bryan! (01:12.055)
                        Ha ha.</p>
                        <p>Bryan! (01:17.527)
                        That&#39;s fucking crazy.</p>
                        <p>Bryan! (01:23.115)
                        It&#39;s like the movie Wolf in but with turkeys. Yep. Turd, turd, turducken. So it&#39;s been a pretty crazy year for us. We experienced some pretty crazy growth. We had a bunch of great guests. I found an audience of listeners that I truly treasure. You guys who I interact with over on Instagram. I love you. Keep listening. I appreciate you.</p>
                        <p>Dave! (01:26.722)
                        Yup, it&#39;s Turk-Turkin. Turk-Turkin 2, electric book-</p>
                        <p>Bryan! (01:48.111)
                        We also found our niche in a very noisy space. We never really set out to be an 80s horror movie podcast, but it just sort of happened naturally. I guess I like to think that I stay contemporary, you know, like I...</p>
                        <p>Dave! (01:54.878)
                        Yeah, didn&#39;t we though? I mean, come on. Let&#39;s be honest.</p>
                        <p>If this list that we&#39;re looking at has taught us anything, it&#39;s that this was inevitable.</p>
                        <p>Bryan! (02:06.72)
                        Yeah. But yeah, I do eventually color outside the lines. This sort of happened that way. It&#39;s weird. But yeah, we do watch modern horror movies. And we thought it might be prudent to present a roundup of 2023 winners and losers as far as we&#39;re concerned. So yeah, things that I watched in 2023. Some I liked. Pepperidge Farms remembers.</p>
                        <p>Dave! (02:21.618)
                        shit that I watched.</p>
                        <p>Dave! (02:25.922)
                        This Things I Remember.</p>
                        <p>Bryan! (02:31.219)
                        Just before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between episodes, you can find us on Instagram at BringMeTheAxPod, and you can find Dave at ThatQueerWolf. We&#39;re having a good time over there, and you will too. I pretty much guarantee it. We&#39;ve also got a sweet website at BringMeTheAx.com. You can listen to all our past shows there and read the transcripts. And you can also contact us directly at BringMeTheAxPod at gmail.com with any questions, comments, or suggestions. Do let us know if there&#39;s a movie that you love and like to hear us give it the business.</p>
                        <p>Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. You&#39;ll be doing us a favor by leaving us a five-star review on Apple Podcasts and Spotify. We&#39;re also over on YouTube now if you prefer to consume your podcast. Yep, if you want to do podcasts that way, we would love it if you subscribed over there as well. You know, tell your fucking friends.</p>
                        <p>Dave! (03:17.15)
                        just tell your friends tell your friends about it tell your mom tell your English teacher in high school tell the babysitter tell your doctor i don&#39;t i&#39;m gonna tell my first girlfriend for sure</p>
                        <p>Bryan! (03:24.187)
                        get your first girlfriend, your first boyfriend.</p>
                        <p>Bryan! (03:30.719)
                        Yeah, yeah, let&#39;s call him up. Just be like, Hey, remember me? No, that&#39;s too bad. Well, anyways, I got this pot. Boy, so yeah, 2023 in review. I saw way more movies this year than I was expecting to.</p>
                        <p>Dave! (03:34.818)
                        Oh, I bet she remembers me.</p>
                        <p>Dave! (03:48.166)
                        I did too, oddly enough. I was like, well, I bet I&#39;ve seen five of these and I saw a lot more than five.</p>
                        <p>Bryan! (03:54.905)
                        I pulled it up. I&#39;ve been, and just because it&#39;s kind of a characteristic of my personality, I obsessively catalog everything. And so I&#39;ve got one of those letterboxed accounts where you just like, hey, I watched these movies on this day, and you can leave thoughts. I do it on Instagram every now and then with sort of like, you know.</p>
                        <p>snarky remarks about the movies that I watched. And like I looked at all the stuff I watched in 2023. And I was like, damn, like I&#39;ve seen that many movies. It was mostly horror movies, like, you know, so we figure we&#39;ll start from the bottom up the bad because there were way more bad movies than good movies. So I Yeah, I intend to bring up Pet Cemetery Bloodlines, which, you know, I&#39;m not surprised. It was sort of like on one day. And so it&#39;s like, you know, fuck it, we&#39;ll watch it.</p>
                        <p>Dave! (04:28.898)
                        prize.</p>
                        <p>Dave! (04:35.106)
                        I didn&#39;t see it.</p>
                        <p>Dave! (04:41.642)
                        All this shit that&#39;s on Hulu, I mean, I think we have Hulu, but I don&#39;t think I&#39;ve ever, like I don&#39;t watch these movies ever. I mean, I watch a lot of movies. I don&#39;t watch a lot of TV. And so it&#39;s like, if it&#39;s not on Shudder or Sitting on My Shelf or the Hallmark Channel, I probably haven&#39;t seen it. Was Pet Sematary Bloodlines on the Hallmark Channel?</p>
                        <p>Bryan! (04:47.895)
                        Yeah, I.</p>
                        <p>Bryan! (04:55.587)
                        Yeah.</p>
                        <p>Bryan! (05:00.183)
                        Yeah, yeah. But...</p>
                        <p>Bryan! (05:05.62)
                        Maybe? Question mark? Yeah, so this movie, I&#39;ll give you a little rundown. It&#39;s basically you&#39;ve read the you read the novel. Yeah. So the it&#39;s I can&#39;t remember if it&#39;s just a part of a chapter or if it&#39;s a chapter. It&#39;s the Timmy Bateman part of like, well,</p>
                        <p>Dave! (05:07.217)
                        It was not and that is why I have not seen it.</p>
                        <p>Dave! (05:17.026)
                        I have ya.</p>
                        <p>Dave! (05:26.406)
                        Okay, well, which is one of the cooler parts of the book.</p>
                        <p>Bryan! (05:30.095)
                        true. This expands it greatly and it&#39;s all fucking nonsense bullshit arbitrary lore building. Like there&#39;s</p>
                        <p>Dave! (05:36.926)
                        Is it, do they still rip off like, this is a native legend? Do they do that still? Oh God, come on.</p>
                        <p>Bryan! (05:41.419)
                        Oh, yes. Oh, yes. 100%. But they actually, you know, because it&#39;s, you know, it&#39;s a 2023 movie, they&#39;ve actually got like, indigenous people in the movie, you know, so but yeah, there&#39;s this whole thing where like, in order to kill the thing that comes back, you have to like, shoot it in the eyes or something, which was just completely lame.</p>
                        <p>Dave! (06:03.534)
                        This is how you know movies don&#39;t need to be remade. When you&#39;re really, like really going out of your way to be like, how can I make this fresh? Well, here&#39;s some dumb shit. Okay, come on.</p>
                        <p>Bryan! (06:11.903)
                        Yeah, it&#39;s it reeks of producer notes is the problem. It&#39;s like, you just didn&#39;t have to do that. But I mean, really, honestly, the worst its worst crime is that it&#39;s boring and it&#39;s forgettable. Like I</p>
                        <p>Dave! (06:24.438)
                        It&#39;s also such that the Pet Sematary is such a quintessentially 80s story that to try to elevate it or take it in any direction is like, it&#39;s not going to work. I mean, they did it. They did the remake and it was like, wow, this movie sucks. And probably not. It&#39;s not everyone, you know, it&#39;s not their fault. I think it probably just got noted to death, but.</p>
                        <p>Bryan! (06:43.839)
                        Yeah, but it had, I mean, on the upside, it had a really great cast. It had Henry Thomas in it. It had Samantha Mathis and David Duchovny as well. Yeah, so, yeah. So, you know, it had Zach going for it, which is nice. Following that up was The Boogie Man, which was also Stephen King. It&#39;s in Night Shift.</p>
                        <p>Dave! (06:52.716)
                        Eugh. Oh, Maldoran.</p>
                        <p>Dave! (07:02.023)
                        Also, a Stephen King story.</p>
                        <p>And it&#39;s a fairly straightforward story that&#39;s like, if you were to be like, hey, which Stephen King short story do you think is gonna make a great feature length movie? I would not choose this one.</p>
                        <p>Bryan! (07:15.723)
                        Yeah, it&#39;s like trucks. It&#39;s like 20 pages long. And it there&#39;s not a lot of like, it&#39;s the sort of thing that like, in order to turn it into like a feature film, you have to extrapolate greatly and sort of like add a lot to it. See above. It&#39;s like bloodlines. It was just it was boring. There was so much fucking hype about it too. When it came out, like Stephen King wouldn&#39;t shut up about how great it was. But like, as we&#39;ve kind of come to find out, like</p>
                        <p>Dave! (07:17.835)
                        Mm-hmm.</p>
                        <p>Bryan! (07:44.277)
                        you write him a check he&#39;ll say whatever you want him to.</p>
                        <p>Dave! (07:45.974)
                        Yeah, I mean, he&#39;s the Jamie Lee Curtis of authors. He&#39;s just like, this was the best one ever. And it&#39;s like, dude, they can&#39;t all be the best one ever. Not like, not everyone can be the future of horror.</p>
                        <p>Bryan! (07:48.815)
                        I&#39;m the man who won that one.</p>
                        <p>Yep.</p>
                        <p>Bryan! (07:57.663)
                        Yep, only Clive Barker apparently. So following that up, no one will save you, which...</p>
                        <p>Dave! (08:03.866)
                        I know nothing about this movie. I&#39;ve heard the name a bunch of times and it was like, this looks like an alien story.</p>
                        <p>Bryan! (08:10.559)
                        It is okay. So it&#39;s about it&#39;s about a woman whose town and most specifically her home comes under attack by like aliens in a UFO. But the gimmick and it&#39;s really in love with this gimmick is that nobody says a word in it.</p>
                        <p>Dave! (08:33.836)
                        Ugh.</p>
                        <p>Bryan! (08:34.539)
                        Yeah. So like, that&#39;s been done before. And like, I always think of the Buffy the Vampire Slayer episode with The Gentleman. I think it&#39;s a great episode. It&#39;s the one that drug that drove that drew me and like, I ignored that show for a really long time. And then I saw that one with Bridget one night. And I was like, Holy shit, like, that&#39;s what Buffy the Vampire Slayer is. She&#39;s like, Yeah, it&#39;s stupid that you haven&#39;t watched it.</p>
                        <p>Dave! (08:43.202)
                        which is an okay episode. It&#39;s visually very cool.</p>
                        <p>Dave! (08:59.486)
                        Yeah, well, you add Doug Jones to anything and it is gonna be really fucking cool because that guy is awesome. I&#39;ll tell you what though, you wanna see that shit work out really well. There is an episode of Bojack Horseman that is completely dialog-less, maybe with the exception of like some shit at the beginning and end. That is an example of how to do it really well. Because I think that show did everything really well.</p>
                        <p>Bryan! (09:05.003)
                        Yeah. But like everything, everything about it.</p>
                        <p>Bryan! (09:12.749)
                        Yeah.</p>
                        <p>Bryan! (09:24.028)
                        You know what? I&#39;ve never seen a single episode of it.</p>
                        <p>Dave! (09:25.63)
                        It is the most heartbreaking thing I&#39;ve ever seen ever anywhere. It is absolutely brilliant. It is one of the best shows I think that has ever been on television. And it is it will break you apart nine times out of 10 because it is despite being a bunch of anthropomorphized animated animals. It&#39;s the most human show ever created. For better or worse.</p>
                        <p>Bryan! (09:39.087)
                        Jesus Christ.</p>
                        <p>Bryan! (09:48.911)
                        Is that an adult swim?</p>
                        <p>Dave! (09:52.14)
                        Netflix.</p>
                        <p>Bryan! (09:53.283)
                        Okay. But yeah, other like, this was a huge, huge hype project. I am like one of like five people who did not like this movie. Apparently, I just I strongly rejected it. I just I hated it. I thought it was fucking</p>
                        <p>Dave! (10:05.446)
                        Aliens aren&#39;t my bag and neither are gimmicks. I really hate gimmicky shit, cause it&#39;s like, if this is the best you&#39;ve got, then fuck off.</p>
                        <p>Bryan! (10:11.543)
                        The gimmick was really fucking boring. The aliens were super crappy. It was all CGI aliens and they look like CGI aliens. You just nothing really worked and there&#39;s this whole trauma subtext to it that just feels tacked on because every modern horror movie has to have some kind of trauma subtext to it.</p>
                        <p>Dave! (10:27.118)
                        Dear God.</p>
                        <p>Dave! (10:33.718)
                        Look, if you have to beat us over the head with this shit, then you&#39;re not as good at this as you think you are.</p>
                        <p>Bryan! (10:39.511)
                        Yeah. Yep. No, thank you. No one will save you. Bringing up the after that there was onyx the fortuitous and the talisman of souls. Are wicked and then and then it went to streaming. So are you familiar with the character?</p>
                        <p>Dave! (10:49.162)
                        This got real limited release though.</p>
                        <p>Dave! (10:55.438)
                        Only in like weird clips you have sent me. That&#39;s about it. He&#39;s like a goofy, like a kind of chubby nerd who carries a lunchbox. Like he seems like a very like adult swim circa 2004. Like this has a real kind of like too many cooks vibe to it.</p>
                        <p>Bryan! (10:58.356)
                        Okay. So like.</p>
                        <p>Bryan! (11:04.491)
                        Yeah, yeah. And he, you know, he&#39;s</p>
                        <p>Bryan! (11:11.547)
                        Yeah, so.</p>
                        <p>He had a yeah, it&#39;s actually no that shit&#39;s that&#39;s that stuff&#39;s a little twisted and weird. This is just he&#39;s just an awkward nerd.</p>
                        <p>Dave! (11:23.518)
                        It&#39;s like a less funny Zach Galifianakis.</p>
                        <p>Bryan! (11:27.243)
                        maybe but the thing is okay so here&#39;s the thing it&#39;s a kid it&#39;s a guy it&#39;s a guy named andrew bowser i think that&#39;s his name and when he does the joke it&#39;s like in five minute segments and when he does it like that it&#39;s fucking hilarious it&#39;s it when it&#39;s short it&#39;s super funny like he really kind of he&#39;d been doing it a little bit before this but he came onto everybody&#39;s radar when he did a segment when the satanic temple revealed that statue and so he shot this</p>
                        <p>short that made it look like he was standing in line to see the statue. And that one is the one that got around and kind of exposed him to everybody. It is objectively very funny. But when you when you when you stretch it out to like feature length movie, it gets old after about five minutes. I&#39;m just like, Oh, god, this was exactly what I was afraid of what happened.</p>
                        <p>Dave! (12:15.862)
                        Yeah, cause awkward is funny for a minute. Awkward becomes exhausting after about 15 minutes.</p>
                        <p>Bryan! (12:21.931)
                        Yeah, I was looking forward to this one too, because like, again, I think the character is really funny. But my worst fear was like realized almost immediately. But it seems like the movie itself seems like there&#39;s a story and like Barbara crampons in it for like a few minutes and</p>
                        <p>Dave! (12:39.006)
                        Well, I think she&#39;s contractionally obligated to be in every horror.</p>
                        <p>Bryan! (12:41.775)
                        She&#39;s kind of like that these days. But yeah, like it&#39;s got a decent cast, but it seems like a bunch of vignettes that were all put together in a writer&#39;s room by like different writers and then slam together into a single movie and just in the end, it doesn&#39;t. And there&#39;s like a really long song and dance scene where they recreate the meatloaf video for I Would Do Anything for Love. Yeah.</p>
                        <p>Dave! (12:57.898)
                        Why that doesn&#39;t sound jarring at all.</p>
                        <p>Bryan! (13:11.084)
                        Yeah, so.</p>
                        <p>Dave! (13:11.542)
                        You know what&#39;s really interesting that people still do this shit like that was a funny gimmick in Blazing Saddles because that was 1970 what three four it doesn&#39;t work anymore because we&#39;ve seen it in so many goddamn things stop putting jokey songs in your horror movies or whatever just stop doing it</p>
                        <p>Bryan! (13:23.947)
                        Yeah.</p>
                        <p>Bryan! (13:34.711)
                        Yeah, but I mean, it&#39;s it is it is it&#39;s stated that it&#39;s a horror comedy. It&#39;s just it&#39;s a horror comedy that stops being funny after like a couple of minutes. So I got a few more on here. What do you got that&#39;s that I don&#39;t think is on my list. Or or just in general, see where.</p>
                        <p>Dave! (13:49.95)
                        It&#39;s not on your list. Well, so what I got that you didn&#39;t see Hell House, Hell House, LLC origins. Do you know anything about this franchise?</p>
                        <p>Bryan! (14:00.167)
                        I&#39;ve watched the very first one and I thought it was okay.</p>
                        <p>Dave! (14:03.938)
                        That was sort of where I was at. I was like, well, this is, this all feels very familiar, but as far as a found footage horror movie goes, sure, why not? I think I&#39;ve seen it a couple of times at this point. And then I watched the second one and then the third one, and then this one, and it&#39;s like, you keep making the same, I&#39;ve seen them all. Like you keep making the same movie and there&#39;s not much variation between, and I know a lot of people really liked this movie. And my question is why, if you&#39;re a director,</p>
                        <p>Bryan! (14:18.772)
                        Oh shit, so you&#39;ve seen them all.</p>
                        <p>Dave! (14:32.502)
                        But you succeed pretty, you know, pretty handily with your first movie. Why is your decision to keep making that? Because presumably, yeah, same guy, same crew the whole time. It&#39;s like, presumably you have probably gotten offers to do other shit by this point. I mean, you have a deal with shutter. So why do you keep making the same story? Like, and I think when that is the question, the answer is probably almost always that this is all you&#39;ve got.</p>
                        <p>Bryan! (14:42.015)
                        Is it always by the is all the same?</p>
                        <p>Bryan! (14:50.2)
                        Oh yeah.</p>
                        <p>Dave! (15:00.47)
                        Like you&#39;re kind of a one trick pony. And this movie has, I think it had some decent scares and some unsettling moments. It&#39;s essentially like most of them, kind of a locked box story. They&#39;re all in this house problem. With this one, the characters are so thoroughly unlikable. Like, you know, it&#39;s this idea of like, it&#39;s kind of a riff on what&#39;s the main character? Is it Heather in Blair Witch?</p>
                        <p>Bryan! (15:00.707)
                        Yeah.</p>
                        <p>Bryan! (15:19.18)
                        Yeah.</p>
                        <p>Dave! (15:29.354)
                        It&#39;s a little bit like that where like she&#39;s so driven and like she&#39;s she kind of seems like a bitch. And it&#39;s like, it&#39;s just a fucking tired trope of like the bitchy woman who&#39;s like obsessed with them. Like, this is boring. The clown thing is kind of obnoxious at this point. Like, I don&#39;t think clowns are scary. I think the people that are super like, oh, my God, they&#39;re terrifying. I&#39;m like, yeah, are they, though? Are you kind of playing it up? Maybe a little bit.</p>
                        <p>Bryan! (15:52.143)
                        I lived, when I was in school, I had this poster that had like, it was a promo for this animation called Bingo the Clown, which was like a demo software for this thing called Maya that I think all the special effects like VFX people use today, but they did it with this thing that involved clowns. And my roommate&#39;s girlfriend saw it and had a fucking nervous breakdown. So people are apparently very afraid of clowns.</p>
                        <p>Dave! (16:19.103)
                        I mean, I don&#39;t know, maybe. I think that shit&#39;s a little bit played out and pretty boring.</p>
                        <p>Bryan! (16:24.301)
                        I don&#39;t get the clown thing, not scary. And when you have to go out of your way to make them scary, like, ugh, you know.</p>
                        <p>Dave! (16:29.502)
                        Yeah, like when they&#39;ve got blood on their mouths and shit. And like, there&#39;s like a weird eighties, kidnappy subplot to this that doesn&#39;t make a lot of sense. Like a sixties, I don&#39;t know. It just doesn&#39;t make a lot of sense. It&#39;s a lot of shit thrown in the blender. It feels like it&#39;s just kind of checking spooky boxes. I think he is capable of making something interesting. I just don&#39;t know why he keeps making the same fucking.</p>
                        <p>Bryan! (16:43.032)
                        Yeah.</p>
                        <p>Bryan! (16:49.683)
                        Who knows? It could be a thing that&#39;s just like, oh shit, I&#39;m gonna give the people what they want, you know?</p>
                        <p>Dave! (16:53.43)
                        I mean, that&#39;s my assumption is like, well, it worked the first few times, don&#39;t mess with a good thing. And it&#39;s like, well, all that tells me is that you&#39;re not a very creative person then. Which might be unfair, but it&#39;s like, you haven&#39;t shown me anything else yet, so.</p>
                        <p>Bryan! (17:07.587)
                        Yeah.</p>
                        <p>Dave! (17:09.742)
                        Wait a minute, what else did I watch that you didn&#39;t see? Oh my God, something called She Came From The Woods. Now this was, I just have a note that says so forgettable and awful. I think this was a 2B original, and I didn&#39;t know that when I tracked it down. I went through a run recently of 2B originals, and I&#39;ll tell you what, they are all real fucking bad.</p>
                        <p>Bryan! (17:15.271)
                        Oh, you told me about this one. I have not seen this one.</p>
                        <p>Bryan! (17:23.169)
                        Ha ha</p>
                        <p>Bryan! (17:26.952)
                        Oh no.</p>
                        <p>Bryan! (17:33.079)
                        Well, here&#39;s the thing is I love to be because of the absolute it is a black hole of just of oh yeah delicious</p>
                        <p>Dave! (17:40.458)
                        Yeah, I have been out there championing to be for years. And people are like, what they were like, what is to be? And now it&#39;s like everyone&#39;s like, oh, to be so great. And you fucking bastards.</p>
                        <p>Bryan! (17:50.536)
                        Yeah, it is a black hole of just delicious garbage, but it is garbage. And so if they&#39;re like</p>
                        <p>Dave! (17:53.442)
                        Yep. Yeah, this is a this movie is a blend of the Blair Witch project and like every 80s slasher, like it&#39;s really trying to capitalize on the kind of was a fear street that did the trouble. Gee, with the middle one was like a slash or camp movie. It&#39;s that everybody&#39;s a stereotype. It&#39;s tropes galore. I mean, you got William Sadler and he&#39;s great for a little bit. But the movie was just fucking garbage. It&#39;s.</p>
                        <p>Bryan! (18:11.508)
                        Uh, yes, yes.</p>
                        <p>Dave! (18:23.418)
                        It&#39;s a to-be original. That&#39;s pretty much all I should have to say, I guess.</p>
                        <p>Bryan! (18:27.115)
                        Yeah, yeah. Oh boy. So let me let me hit you with another one. I saw this one is probably gonna drive some people nuts because I apparently again, I&#39;m the only person who did not care for it. Suitable flesh, which was supposed to. It was so again, Barbara cramped and</p>
                        <p>Dave! (18:43.147)
                        Never heard of it.</p>
                        <p>Wait, no, I have heard of it, but that&#39;s about as far as it goes. Oh, contractually obligated.</p>
                        <p>Bryan! (18:48.843)
                        Yeah, Barbara Crampton, Heather Graham. But the whole the whole draw of it was it was sold like a Stuart Gordon HP Lovecraft thing because it is it is an adaptation of the thing on the doorstep, which is one of my favorite Lovecraft stories. And it&#39;s a shame that this one ends up where it does because</p>
                        <p>I don&#39;t really get it. I&#39;m like a fucking lifetime member of the HP Lovecraft Historical Society. Like, I&#39;m a fucking fan, and I never get to talk about him very much. Unfortunately, the best adaptations were made in the 80s. By Stuart Gordon. This one was written by Dennis Paoli.</p>
                        <p>Dave! (19:21.867)
                        Yeah, bye Stuart Corden.</p>
                        <p>I will say though, a close runner up is those BBC four, that series that they did the was it called the Lovecraft?</p>
                        <p>Bryan! (19:33.527)
                        Uh, the, uh, I&#39;ve heard all of them except for the latest one. And yes, agree. 100%. Those are fan-tastic.</p>
                        <p>Dave! (19:40.214)
                        Yeah, go look that shit up because that show was fucking great. I mean, I wish I could remember what it&#39;s called. Left Cart in Chronicles or Vest?</p>
                        <p>Bryan! (19:43.019)
                        Lovecraft case Lovecraft files, Lovecraft case files, something like that. There&#39;s I know we suck. They&#39;re so fucking good though. Um but yeah, it had a lot of hype. Dennis Paoli was the writer on it. He wrote the you know, the whole 80s ones. Uh it was supposed to be like spiritually in tandem with like uh from beyond because it was like sexy.</p>
                        <p>Dave! (19:48.258)
                        God, we&#39;re terrible.</p>
                        <p>Dave! (20:09.506)
                        Which is like the least of the 80s ones. I think it&#39;s a fun movie, it&#39;s gross as shit.</p>
                        <p>Bryan! (20:15.475)
                        I love it. Love it to death. But yeah, definitely a minor leaguer one for some reason because I think that it&#39;s very, very good. I think it was just unavailable for a really long time.</p>
                        <p>Dave! (20:24.47)
                        I mean, if you don&#39;t get Richard Bands to do your music, then get the fuck out of here. What are you doing, Suitable Flesh? Where&#39;s my Richard Bands soundtrack?</p>
                        <p>Bryan! (20:27.587)
                        What the fuck are you, yeah, what the fuck are you doing? But.</p>
                        <p>Bryan! (20:33.303)
                        Yeah, it was supposed to be like, it was a big hype. It was a thing. It failed to deliver on its promise as far as I&#39;m concerned. It was hammier than campy, which I think is a very important distinction to make. There were times where I was like fucking cringing, but I will say this, horror movies could stand to be a little sexier these days. Yeah.</p>
                        <p>Dave! (20:51.822)
                        Oh yeah. They could stand to be a lot of things these days. A lot of things that aren&#39;t depressing and tragic.</p>
                        <p>Bryan! (20:58.415)
                        Yeah, yeah. This one definitely tried to go in the direction that like in the sort of opposite direction, it was, you know, supposed to be kind of fun and campy and silly. It&#39;s, you know, there&#39;s some decent, there&#39;s some decent gore. But there are just the acting is so over the top.</p>
                        <p>Dave! (21:14.454)
                        You know, I think the problem is, because I think about this a lot, you know, as we previously stated that we are a bit stuck in the past when it comes to the shit we really enjoy. And I think a lot of it is like, when people made this stuff in the 70s and 80s, and even into the 90s a little bit, it was done with genuine enthusiasm, but it wasn&#39;t done with like, isn&#39;t this gonna be funny, wink, wink kind of thing. And it&#39;s like, look, you can&#39;t, Camp, I don&#39;t give a fuck what anybody says. Camp is unintentional.</p>
                        <p>Bryan! (21:23.832)
                        Yeah.</p>
                        <p>Bryan! (21:42.476)
                        Yeah, oh yeah.</p>
                        <p>Dave! (21:42.486)
                        There&#39;s a reason why people are drawn to it. And by people, I mean gay men. It is, there&#39;s a reason why this shit happens. It&#39;s not a thing you could set out to do. So when like, you&#39;re like, we&#39;re gonna make this really hilarious throwback thing. I&#39;m like, fuck you. Get out of here with this dumb shit.</p>
                        <p>Bryan! (21:55.019)
                        Oh yeah, yeah. It&#39;s like when the, like, have you seen the movie Birdemic? Okay, so when he made that movie, he made that movie in earnest. Like that was, that was, you know, that was his magnum opus, you know, but then he made sequels and was like, and was aware of what he had done the first time and how people received it. So when he made those ones, they...</p>
                        <p>Dave! (22:01.538)
                        Yes.</p>
                        <p>Dave! (22:17.558)
                        You can&#39;t you can&#39;t capture the magic anymore because the magic was part of that magic is that it is so earnest. It&#39;s done with such genuine love for the thing. And even though we are sometimes laughing at movies, I&#39;m also really appreciative of that joy and that appreciation that goes into the movie. And when you try to do something that is intentional, like say a sharknado or something, I find it offensive because what it&#39;s really doing is that it&#39;s mocking that joy and that</p>
                        <p>Bryan! (22:24.952)
                        Yeah.</p>
                        <p>Dave! (22:47.054)
                        business. And I like that. I&#39;m just kind of like, you can fuck off with that. Like, I&#39;m not I&#39;m not here for your weird, cynical cash grab. Like, even when it&#39;s done by horror people, I&#39;m like, that makes it even worse.</p>
                        <p>Bryan! (22:47.596)
                        Yeah, yeah.</p>
                        <p>Bryan! (22:59.807)
                        I agree 100%. I think that a lot of a lot of what makes the movies that we do like when we do stuff and we are just like it sounds like we&#39;re ripping on them. Like we are a little bit but like for real work. Yeah, but there&#39;s a it&#39;s a not. Yeah, honesty goes a long way in making a movie, you know. Moving on. I think you saw this one five nights at Freddy&#39;s.</p>
                        <p>Dave! (23:11.79)
                        because they are objectively silly, but they&#39;re done with such love that it&#39;s like, it&#39;s so infectious.</p>
                        <p>Dave! (23:25.674)
                        I did see this movie. Wasn&#39;t it originally called Willie&#39;s Wonderland? Isn&#39;t this that same fucking movie that Nicolas Cage made?</p>
                        <p>Bryan! (23:31.703)
                        Oh, no, no. Are you unaware of the sort of legacy of Five Nights at Freddy&#39;s? Yeah, so Willy&#39;s Wonderland is a rip off this of the game because I think they&#39;ve been trying to make Five Nights at Freddy&#39;s for a really long time. And yeah, and so that one came along and just was like, Hey, this looks familiar to that thing. You know, I know, I, the thing is, I don&#39;t think I&#39;m the right audience for this movie.</p>
                        <p>Dave! (23:35.17)
                        Well, it&#39;s a video game, right?</p>
                        <p>Dave! (23:40.308)
                        of the game.</p>
                        <p>Dave! (23:45.998)
                        I think I know why they had trouble making it.</p>
                        <p>Dave! (23:52.682)
                        Yeah, you know which one is better? The one that looks familiar.</p>
                        <p>Dave! (23:59.302)
                        No, I am definitely not either. And I think I said that to you when I watched it. I was like, I don&#39;t know who this movie is for, but I&#39;m pretty sure I&#39;m not it. Yeah, because I don&#39;t understand. I get it&#39;s all, it was all over the place. Like, again, we&#39;re back with that. Like dolls are scary. Clowns are scary. I&#39;m like, no, they&#39;re not scary. You see statues every day. They&#39;re not terrifying, are they?</p>
                        <p>Bryan! (24:05.163)
                        It&#39;s for 15 year olds, basically. That&#39;s the whole thing.</p>
                        <p>Bryan! (24:16.383)
                        Okay. No, but we watched it with ash, who was fucking in it was just like in rapture from this movie, because it was every single thing. He because he knows all about it. All the fucking lore all the detail and there is deep lore. There&#39;s actually a character in the movie who&#39;s played it&#39;s a it&#39;s a cameo by a guy who ran runs a very high profile YouTube channel who basically who basically his whole thing was</p>
                        <p>Dave! (24:42.363)
                        Ugh, gross.</p>
                        <p>Bryan! (24:45.599)
                        He did all these fan theories about it because the game was released sort of episodically. And so they started to kind of like game out like what&#39;s happening over here and what&#39;s the story with this detail and all this. And personally, I have a detail. I have like I have my own theory that this guy&#39;s name is MatPat. And I think that personally, he wrote all of that game simply by musing about it on his YouTube channel. And the game&#39;s creator was like, thanks, MatPat. I&#39;m going to write all this stuff in because later on they&#39;d be like</p>
                        <p>What the fuck, MatPat was right. So I think personally, the game&#39;s creator was just like taking other people&#39;s notes and just agree.</p>
                        <p>Dave! (25:21.238)
                        Well, I did not have an 11 year old rattling on in my ear when I watched this. So I had to make this shit up on my own. And I was like, I think they had moments that were okay. Like this sort of, there&#39;s people inside. I don&#39;t know, it didn&#39;t make sense. You know what it felt like to me? It felt like that shitty 13 Ghosts remake that came out in the early 2000s where it&#39;s like, wow, did you even watch the original movie? Cause it&#39;s not this.</p>
                        <p>Bryan! (25:27.867)
                        Yeah.</p>
                        <p>Bryan! (25:37.685)
                        It doesn&#39;t.</p>
                        <p>Bryan! (25:42.774)
                        Oh yeah.</p>
                        <p>Oh yeah. No, but yeah, I personally, I think the whole Five Nights at Freddy&#39;s thing is fucking stupid. It was made popular because like high profile YouTubers, like fucking PewDiePie and shit, made, built a fucking empire on pretend, on.</p>
                        <p>Dave! (25:59.11)
                        See, you can&#39;t trust these guys. They always turn out to be creeps.</p>
                        <p>Bryan! (26:02.143)
                        Yeah, yeah. And these guys, they what they did was they built they built an entire empire on pretending to be afraid of the game. So that&#39;s kind of how we</p>
                        <p>Dave! (26:08.618)
                        Which again, I think this is the thing that, this is what I was saying about clowns again, which come back to this like, it&#39;s supposed to be scary. Ooh, isn&#39;t it scary? And the answer is no, it&#39;s not. It&#39;s an animatronic thing. Like I get it, the Chuck E. Cheese thing, and then that other fucking one that everybody loves. Yeah, I get, like it&#39;s just cashing in on you sort of dumb cultural things and nostalgia. And I&#39;m just, I&#39;m either, I, it&#39;s, I&#39;m too old for it, I guess is where we&#39;re landing on this, which is fair. I can see why it appeals to people.</p>
                        <p>Bryan! (26:18.191)
                        Yeah.</p>
                        <p>Bryan! (26:35.159)
                        Yeah, yeah. The thing that&#39;s really interesting about that to me though is that a lot of the fans of that IP are like an entire generation removed from ever having the Chuck E. Cheese experience. So it&#39;s this whole hauntology thing that they got going on.</p>
                        <p>Dave! (26:49.834)
                        Right, so to them, it&#39;s a... I still love the term hauntology.</p>
                        <p>Bryan! (26:54.655)
                        Yeah, it&#39;s great. But yeah, it&#39;s we have it&#39;s that you know, this kind of gave rise to the whole analog horror thing which we&#39;re going to fucking I&#39;m going to spike as hard as I possibly can with my fucking worst movie of the year. But before we get there, what you got anything else?</p>
                        <p>Dave! (26:57.334)
                        Way to twist an academic thing, horror people. You did something right for once.</p>
                        <p>Dave! (27:15.894)
                        I do. I&#39;m gonna hit you. I got two more that you did not see. The first one is a movie called Sick that came out the very beginning of the year. Do you remember this one? So this was supposed to be, well, I believe this was supposed to be a big deal because this was a movie that is produced and written by Kevin Williamson of Scream, I know what you did last summer, et cetera, et cetera. And it should have been a big thing and it wasn&#39;t. And I think I know why.</p>
                        <p>Bryan! (27:25.517)
                        I&#39;m familiar with it, but I did not see it now.</p>
                        <p>Bryan! (27:35.871)
                        All right, all right.</p>
                        <p>Dave! (27:45.93)
                        because this was, so I&#39;m gonna step back. I think it is fair to say that there is not a person alive whose life was not dramatically affected and or kind of changed by the pandemic, for better or worse. And we have not yet even begun to reckon with any of that. And so.</p>
                        <p>Bryan! (28:03.66)
                        Sure.</p>
                        <p>Dave! (28:10.902)
                        the long comes this movie that is, it has a very strong political agenda. It really traffics in the more polarizing, divisive political arguments about the pandemic. And I think if we hold it up against something like that, with the host, did you see that? So host was a movie that came out, I think pretty early on, it was either 2020 or early 2021. And it starts from this kind of shared,</p>
                        <p>Bryan! (28:28.587)
                        I have not seen host either.</p>
                        <p>Dave! (28:40.018)
                        a feeling of isolation because everybody was doing the same thing at that time. And so it&#39;s you know, we can all recognize this and it resonates. And then it moves on into more of like a fairly standard kind of like haunting paranormal story. And, you know, it goes there. And I think people it resonated with people because we were all stuck inside. This movie comes along a couple of years later. It is a lot more thorny. And there&#39;s it.</p>
                        <p>Bryan! (28:44.056)
                        Yeah.</p>
                        <p>Dave! (29:09.678)
                        there&#39;s a lot of kind of precarious stuff in it. And I think that goes a long way to explaining why it doesn&#39;t really work because it&#39;s so kind of firmly anchored to this moral plot device about, you know, the consequences of being reckless with public health that when you sort of try to separate it from that, it&#39;s just a really uncompelling story. And...</p>
                        <p>Bryan! (29:28.924)
                        Yeah.</p>
                        <p>Dave! (29:36.67)
                        It&#39;s sort of like, again, it&#39;s another one of those kind of locked room, stock and slash, very familiar. It feels a lot like, what was the, you&#39;re next. It feels a lot like that. And it even tries like it tries to give you this big reveal at the end. And but it feeds you so many breadcrumbs along the way, like by the time you get there, it&#39;s much less of a gasp than it is kind of like a shrug. We&#39;re like, you know, like, yeah, I kind of saw this one coming. And I don&#39;t know, I think.</p>
                        <p>Bryan! (29:45.036)
                        Yeah.</p>
                        <p>Bryan! (29:58.507)
                        Hahaha</p>
                        <p>Dave! (30:04.546)
                        its biggest folly was that you just tried to do this too soon. Like this is a thing. This was a trauma that we have not even begun to process and probably won&#39;t for many, many years. And so to try to make a movie about it when it&#39;s all still so hot and enraging, it just doesn&#39;t work because I think everybody gets caught up in the politics of it. And nobody was really wanting to do that at the time. Like...</p>
                        <p>You know, we&#39;re two years in, it&#39;s kind of like, I don&#39;t want to have to think about that. You know, you got your politics into my horror hypothing. And I don&#39;t think it was, nobody was ready for it or wanted. And it was just generally not a very good thing. It&#39;s real preachy. It just comes across as kind of forced. Well-made, acting&#39;s fine, but it&#39;s like, just not a great story. And it&#39;s just too soon. Yeah.</p>
                        <p>Bryan! (30:35.903)
                        Yeah, yeah.</p>
                        <p>Bryan! (30:42.159)
                        That&#39;s too bad.</p>
                        <p>Bryan! (30:51.199)
                        can conceptually it just doesn&#39;t land right yeah so</p>
                        <p>Dave! (30:54.61)
                        Maybe, you know, it&#39;s the kind of thing that maybe we&#39;ll come back to in a couple of years and be like, this movie actually was pretty good, it was just too early to talk.</p>
                        <p>Bryan! (31:01.027)
                        Gotcha. So yeah, before we hit my absolute worst pick of the year, let me get you, let me, yeah, let me, let me, let me hit you with one more. Did you, you didn&#39;t see, did you see Malam? Fucking hated Malam.</p>
                        <p>Dave! (31:05.354)
                        Oh, I&#39;ve got another one. That&#39;s an absolute worst for you though.</p>
                        <p>Dave! (31:11.53)
                        No, I&#39;m familiar with it. I saw a bunch of shit about it. And I was like, well, maybe I&#39;ll check this one out eventually. But you know, I have, there&#39;s things from the sixties to watch.</p>
                        <p>Bryan! (31:22.563)
                        Yeah. So I saw the original one a few years ago called it&#39;s called The Last Shift. And this is a remake of that by so it&#39;s the same movie, but it&#39;s and this is a remake by the same filmmakers who were provided something like $2 million to make this movie proper. And somehow they made it worse.</p>
                        <p>Dave! (31:31.102)
                        I did see that.</p>
                        <p>Dave! (31:44.898)
                        Here&#39;s the thing though, if you&#39;re not Michael Hanke, you know what, even if you are Michael Hanke, don&#39;t remake your own movies.</p>
                        <p>Bryan! (31:53.062)
                        Yeah. The problem with this is it&#39;s fucking nonsense. It&#39;s just it what it does is there&#39;s a lot of like.</p>
                        <p>Dave! (31:58.078)
                        It looked like gory, gooey stuff and I&#39;m like, eh, hmm. I&#39;ve seen it.</p>
                        <p>Bryan! (32:02.271)
                        Yeah, yeah, like there&#39;s other movies that do that way better. Like this one will do a thing where it&#39;s like your main the main character is like going into a room and then something horrible happens and the lights go out and the lights come back on and she&#39;s in another room entirely. And did that happen? I don&#39;t know. Maybe. But like it does that throughout the entire movie. Right up to the end. Fucking tedious. I was so tired and I had a headache at the end of the movie that I just.</p>
                        <p>There was a movie from several years back called Baskin from Turkey that&#39;s similar, very similar in theme and tone. Way better, much better use of your time.</p>
                        <p>Dave! (32:31.676)
                        Mm-hmm. I know that one.</p>
                        <p>Dave! (32:41.174)
                        Yeah, I feel like I feel like foreign, the foreign markets are doing shit a lot better than Americans are these days.</p>
                        <p>Bryan! (32:46.999)
                        Yeah. Yeah, like Baskin&#39;s got a lot of problems in terms of like its storytelling, but it&#39;s got that long see like sequence at the end with the father that is so fucking unsettling and unpleasant. But so well delivered and just terrifying that like I will I will go back to that one any old time, but I got to be in the mood for that one. But Malam</p>
                        <p>Dave! (33:08.95)
                        Oh, I know what your last I know what your worst one is. But before you get to it, I&#39;m going to give you mine. I watched. So when you propose this recently, I decided, you know, I threw together my list and I was like, well, let&#39;s see if I can jam another one into this. So I watched the sacrifice game. This is a new one on Shutter. It just came out very recently.</p>
                        <p>Bryan! (33:11.715)
                        Probably, probably, but yeah.</p>
                        <p>Bryan! (33:26.803)
                        Oh, God. I. Yeah. Yeah, I&#39;ve seen it. I&#39;ve seen it on the like, hey, you should watch this. And I&#39;m like.</p>
                        <p>Dave! (33:35.018)
                        Yeah, people really love this movie. Hey, you know what? This movie fucking sucked. It was, the cast of Killers is really obnoxious. They&#39;re trying to give you like that Manson family giddy dopey thing that is so fucking tired and trite that like I don&#39;t ever wanna see another movie with like a young woman in like hippie dress shrieking about something. You know, if it&#39;s not Sherry Moon, get the fuck out of here.</p>
                        <p>Bryan! (33:38.904)
                        Ha ha ha!</p>
                        <p>Bryan! (33:54.124)
                        So it&#39;s.</p>
                        <p>Bryan! (33:59.463)
                        So it&#39;s I was gonna say it&#39;s somebody doing a cover of a Rob Zombie movie.</p>
                        <p>Dave! (34:04.17)
                        Yeah, it&#39;s Rob Zombie&#39;s Firefly family, but with a more obvious Manson family vibe. They are insufferable. The plot is also extremely tired. It&#39;s the strangers, it&#39;s funny games, it&#39;s them, but with a demonic twist that I don&#39;t give a shit about. Like, everybody&#39;s like us. Yeah, it is like, this is a fresh new take or whatever. This is not fresh new or anything. It&#39;s like...</p>
                        <p>Bryan! (34:11.06)
                        Oh god.</p>
                        <p>Bryan! (34:23.315)
                        Oh god. So it so it is. It&#39;s the devil&#39;s rejects.</p>
                        <p>Dave! (34:32.566)
                        This is the same shit we have seen for the last 10 or 15, do something better. We were 40 minutes into this movie and I was already like, how much of this is left? I don&#39;t know if I can sit through the rest of this. Like, no, and it&#39;s got some notable people in it. Like one of the main stars is the guy who played Aladdin in the live action Aladdin movie. And he is so uncharismatic and boring that it&#39;s like, you&#39;re the lead in this?</p>
                        <p>Bryan! (34:37.208)
                        Yeah.</p>
                        <p>Bryan! (34:54.763)
                        Oh no shit.</p>
                        <p>Bryan! (35:01.808)
                        Oh god.</p>
                        <p>Dave! (35:02.718)
                        Gus Kenworthy, who I believe was a Olympic skater. He&#39;s also trying to make his foray into acting. I mean, everyone&#39;s like, the acting is sort of like, well, it&#39;s fine, I guess. It gets, it&#39;s like, I don&#39;t know if you remember the Family Guy&#39;s skit where they&#39;re in, like they&#39;re stuck in limbo and they&#39;re like, well, it&#39;s not bad. It&#39;s not good, but it&#39;s not bad. It&#39;s bad. It&#39;s just like, you&#39;re all fine.</p>
                        <p>Bryan! (35:06.232)
                        Hmm.</p>
                        <p>Dave! (35:29.442)
                        This shit is just so tired and it&#39;s made worse when it&#39;s like, you can tell that the filmmakers really feel like they&#39;re making something real cool. And it&#39;s like, no, no. If you were over 32 years old, you&#39;d know that this movie has been made about seven or eight times already and better. It&#39;s just like, this is a tired premise of like, we&#39;re breaking in and we&#39;re gonna terrorize you, but we&#39;re also supposed to be kind of charming and wicked, except they&#39;re not.</p>
                        <p>Bryan! (35:31.201)
                        Yeah.</p>
                        <p>Bryan! (35:37.773)
                        No.</p>
                        <p>Bryan! (35:48.041)
                        Oh no. Damn.</p>
                        <p>Dave! (35:59.266)
                        They&#39;re just awful. It&#39;s like, I can&#39;t wait for all of you to die so this movie can be over.</p>
                        <p>Bryan! (35:59.287)
                        That sucks.</p>
                        <p>Bryan! (36:04.296)
                        Yeah, not gonna watch that one.</p>
                        <p>Dave! (36:05.878)
                        And it&#39;s like, it&#39;s supposed to have this like, you know, this is the big kind of reveal at the end where it&#39;s like, oh, it&#39;s gonna be like a story about sisterhood. And I&#39;m like, is it though? I don&#39;t think so. I mean, Thelma and Louise is a story about sisterhood and it has a pretty good one.</p>
                        <p>Bryan! (36:15.867)
                        Ah, yep.</p>
                        <p>Bryan! (36:22.891)
                        Yeah. All right.</p>
                        <p>Dave! (36:24.425)
                        It sucked I can&#39;t get behind</p>
                        <p>Bryan! (36:26.915)
                        No, thanks. Doesn&#39;t sound very good to me. So yeah, I&#39;m gonna give you a hit you my last one. And like, if it&#39;s we&#39;re talking 2023 movies, and I have not mentioned this one yet, you were gonna probably expect it to be either on my best of or my worst of. But yeah, this one is number one with a bullet for worst movie I saw this fucking year I am very comfortable in saying that this is one of the worst movies that I have ever seen. And I have seen</p>
                        <p>hundreds, thousands of movies. This is one of the absolute fucking worst movies I&#39;ve ever seen. I would go and watch Andy Warhol&#39;s Empire State before I watched this, all of it. And I&#39;m talking about Skin of a Rink, a fucking word I hate saying so goddamn much out loud. This movie, I have no idea. If it is, they need to just fucking.</p>
                        <p>Dave! (37:12.354)
                        It feels Canadian. Is it Canadian?</p>
                        <p>Dave! (37:17.698)
                        I don&#39;t know why they would do that. I love, I love Canada.</p>
                        <p>Bryan! (37:18.915)
                        They would do they should if this was made by Canadian Canada should deport this person. The thing is this movie is fairly equally divided among people who loved it and people who hated it, the thing is.</p>
                        <p>Dave! (37:30.646)
                        This sounds like someone who&#39;s like, I wanna do, this sounds like a great exercise, let&#39;s do it. And then someone was like, you should make this into a feature film and they do and it doesn&#39;t work.</p>
                        <p>Bryan! (37:38.167)
                        this probably would have been okay if it were like a 15 to 20 minute long short film because but stretched out it is it there are long scenes of just a hallway and or like a dark yeah take one of those toy like mr telephone so put it in and at the end of the hallway and just pretend you&#39;re frightened like i&#39;m not convinced that anybody who</p>
                        <p>Dave! (37:53.946)
                        I&#39;ve got a hallway, I don&#39;t need to watch TV, I can just look at that.</p>
                        <p>Dave! (38:04.157)
                        I feel like the theme running through all these movies is, just pretend you&#39;re frightened.</p>
                        <p>Bryan! (38:08.843)
                        Yeah, yeah, I like I say I&#39;m not convinced that anybody who&#39;s praising this movie has actually watched the whole thing. I have. It&#39;s easily one of the work like I said one of the worst movies I&#39;ve ever seen literally.</p>
                        <p>Dave! (38:17.61)
                        And I look, I am not anti experimental film. I love, I&#39;m a huge fan of Kenneth Hanger. His movies are nonsense.</p>
                        <p>Bryan! (38:22.583)
                        Dude, I love, I love avant-garde shit. I will watch weird crap all the time. But the thing is, is that stuff tends, Kenneth Anger, weird as fuck, but it&#39;s also super compelling. This is not, it&#39;s like, yeah, like nothing, literally nothing happens in it.</p>
                        <p>Dave! (38:37.695)
                        We can&#39;t all be David Lynch, all right?</p>
                        <p>I mean, I guess I give people credit for trying because how else will I know if there is a new David Lynch if people don&#39;t keep trying? But everything I&#39;ve heard and read about this, I was like, well, this sounds like it is a movie that is not for me.</p>
                        <p>Bryan! (38:56.111)
                        really know who it&#39;s for. It feels it&#39;s it&#39;s up its own ass like right out of the gate. It feels like it was like somebody saw Ari Aster and was like, oh <em>**</em> I could do that too. I had to four.</p>
                        <p>Dave! (38:58.091)
                        I have ADHD and I get bored very easily.</p>
                        <p>Dave! (39:05.1)
                        Ugh.</p>
                        <p>Dave! (39:10.867)
                        You know what? I-I-I&#39;m ffff- Some-someone needs to stick a turkey on our reaster.</p>
                        <p>Bryan! (39:15.623)
                        Yeah, I&#39;m telling you, I had to force myself to finish this movie several times. There were there were times when I&#39;m watching it and I was like, I could be doing literally anything else right now. Like, what am I doing right here? And I forced myself to finish it because I wanted to write about it. And then I fucking savaged it just like I&#39;m doing now, just.</p>
                        <p>Dave! (39:31.086)
                        Well, I&#39;m going to tell you what, I&#39;m going to hit you with one last one, and it&#39;s totally unfair, completely uncalled for and wildly inappropriate for me to do this because I only watched about 20 minutes of this movie. It&#39;s a wonderful knife. Holy shit. I cannot do the let&#39;s go back in time, let&#39;s do parallel time. Let&#39;s let&#39;s find something new. Let&#39;s find a new trope, kids, because this one is done.</p>
                        <p>Bryan! (39:41.39)
                        I know what you&#39;re gonna say, go.</p>
                        <p>Mm. Mm-hmm.</p>
                        <p>Bryan! (39:54.661)
                        Okay, so yeah, we&#39;ll talk a little bit about that when we get to the next section of the list because one of those sort of like new school horror comedies is on my next part. And</p>
                        <p>Dave! (40:06.302)
                        And I know why they do it and I don&#39;t like it. Like it just sets up that like, isn&#39;t it funny the way people used to talk? And it&#39;s like, well, actually, no, it&#39;s not funny that we used to use words like that, but okay, make a joke about it if you want to.</p>
                        <p>Bryan! (40:18.059)
                        Yeah, yeah, so this next section these are these movies that I considered, okay I&#39;m gonna start this list out with VHS 85</p>
                        <p>Dave! (40:26.562)
                        I&#39;m gonna tell you right now my first note says BOOOOO</p>
                        <p>Bryan! (40:31.948)
                        I&#39;ve seen, I saw the first two of these, and these move, these are always, I love an anthology, I think</p>
                        <p>Dave! (40:34.068)
                        I&#39;ve seen all of them.</p>
                        <p>Dave! (40:38.982)
                        I hate an anthology, so that&#39;s probably where I go wrong here. I do have good things to say about this m-</p>
                        <p>Bryan! (40:42.135)
                        Okay, yeah, see, I love, I love an anthem. Yeah, I love an anthology. And the thing is, is the VHS is our most anthology movies that are, you know, like a collaboration of filmmakers are always a mixed bag. This</p>
                        <p>Dave! (40:57.942)
                        The wraparound story in this feels a little bit clumsy and sort of stumbling. I liked the God of Death story a lot.</p>
                        <p>Bryan! (41:02.059)
                        It, it had...</p>
                        <p>Uh that one was pretty interesting.</p>
                        <p>Dave! (41:07.658)
                        That&#39;s a Gigi Salgarero one. And I liked that they included not just women directors. I liked that they included a Mexican woman. I liked that the story feels very Mexican. There&#39;s other ones though that I was like that weird tech god one. I was like, what the fuck is this? And how did this sneak in here? This is awful.</p>
                        <p>Bryan! (41:15.765)
                        Oh yeah.</p>
                        <p>Bryan! (41:19.396)
                        Mm-hmm.</p>
                        <p>Bryan! (41:30.125)
                        the techno god one terrible. I liked the I liked very end because it felt like</p>
                        <p>Dave! (41:36.83)
                        It falls apart, it doesn&#39;t make much sense at the end. It feels 80s and great up until that, and then you&#39;re like, oh, what?</p>
                        <p>Bryan! (41:43.795)
                        it was like they wrote it to a point where they didn&#39;t really know what to do with it and then we&#39;re like, okay, so tentacles, I guess.</p>
                        <p>Dave! (41:50.294)
                        Yep. What I, the way I felt about this when I finished watching it was this feels like a movie made by makeup and effects people where they were like, we want to do really cool looking shit. It doesn&#39;t matter what the stories are because that is, that&#39;s exactly what happens. The gore effects in this are spectacular. It is, it is, it is bloody and gory and messy as fuck. And it was great because it was all done with such glee.</p>
                        <p>Bryan! (42:00.141)
                        Yeah.</p>
                        <p>Bryan! (42:10.215)
                        Oh yeah, yeah.</p>
                        <p>Dave! (42:18.838)
                        that I really appreciated that. But like narratively, I&#39;m just like none of this shit goes together. It&#39;s pretty boring. Like if you just want to like, I don&#39;t know, watch people get chopped up in hilarious ways. OK, fine.</p>
                        <p>Bryan! (42:19.235)
                        Oh yeah.</p>
                        <p>Bryan! (42:30.923)
                        Yeah, that each the thing with the thing with the whole VHS thing is it&#39;s supposed to feel like these are a collection of video tapes of things that really happened. And a couple of them really kind of get</p>
                        <p>Dave! (42:43.214)
                        But that&#39;s a thing that I think resonates with a certain age group. I don&#39;t know how it resonates with younger audiences, though. Like, if you didn&#39;t grow up with, like, trading tapes and seeking out faces of death, then this shit might feel a little bit boring. And I did grow up with those, and I still think it&#39;s boring.</p>
                        <p>Bryan! (42:50.979)
                        That is oh no.</p>
                        <p>Bryan! (42:57.791)
                        I think that it all kind of. No, I think that this goes back to a lot of like the hotness on YouTube, like the like the back rooms, and a lot of that. Oh, God, what is it? There&#39;s there&#39;s one that is, it looks like after the channel goes off the air for the night, they run this like tape that kind of sounds like</p>
                        <p>I&#39;m gonna cut this out because I have no idea where I&#39;m going with this. It&#39;s really cool though. It looks like it&#39;s instructions from the government in order to resist some sort of invading enemy by literally everybody in America killing themselves and then it goes</p>
                        <p>Dave! (43:34.946)
                        Jesus Christ, everybody, look, y&#39;all, just watch Videodrome. David Cronenberg is better than all of us and he always will be, so just do that and skip this movie.</p>
                        <p>Bryan! (43:39.947)
                        Yeah, yeah, but...</p>
                        <p>Bryan! (43:45.131)
                        Yeah, I mean, that&#39;s the thing. I think like from, as far as I&#39;m concerned, this analog thing can kind of chill out, but also I might be too old for it. The kids love it.</p>
                        <p>Dave! (43:52.934)
                        I think that&#39;s more, I think that&#39;s, we are slowly approaching the realization that these movies just aren&#39;t for us.</p>
                        <p>Bryan! (43:59.667)
                        Oh, sure. There&#39;s a bunch of titles on this list that I think just don&#39;t resonate with me because I&#39;m just too old for it. But yeah, so my next one, actually, let me throw it over to you. What do you got?</p>
                        <p>Dave! (44:12.718)
                        Let&#39;s see, I don&#39;t know if I got anything on this section that you didn&#39;t see. Well, I will, you know, I&#39;m gonna hit you, I&#39;m gonna take on a real thorny one that might get me some bad reactions. Scream 6, let&#39;s talk about a movie that is completely unnecessary. Because here&#39;s my question, here&#39;s my question to you. Name one other franchise where people get to the sixth installment and they just can&#39;t fucking wait.</p>
                        <p>Bryan! (44:17.548)
                        Bye.</p>
                        <p>Bryan! (44:28.687)
                        Okay.</p>
                        <p>Bryan! (44:41.044)
                        I know. I...</p>
                        <p>Dave! (44:41.918)
                        Yeah, yeah, because you know what? These the first screen movie is phenomenal. It was a revelation when it came out.</p>
                        <p>Bryan! (44:48.351)
                        objectively, objectively fucking great.</p>
                        <p>Dave! (44:51.89)
                        I think Bridget and I saw it three or four times in a row in the theater when it came out. And it was a great movie. The second one, that was like one&#39;s pretty good. It&#39;s saved mostly by, what is her name? Aunt Jackie. I feel bad that I can&#39;t remember her name right now, but she&#39;s phenomenal in that. She really, really carries that movie. But.</p>
                        <p>Bryan! (44:56.747)
                        Yeah, I probably would. I... It&#39;s a... It&#39;s pretty good.</p>
                        <p>Bryan! (45:09.027)
                        Oh yeah, I can&#39;t remember. Yeah.</p>
                        <p>Dave! (45:19.722)
                        It&#39;s not. And then it just sort of declines slowly from there. Now, this is one. So I had not been to the movies, but to a theater in more than a decade. And I got talked by some friends into going to this. Well, they rented the whole theater. So it&#39;s like. I did. And it was like it was fine. I feel like this one and the one before it.</p>
                        <p>Bryan! (45:22.259)
                        It comes apart pretty quickly after that. Yeah.</p>
                        <p>Bryan! (45:34.175)
                        Oh yeah, you went with the morbid crew.</p>
                        <p>Dave! (45:46.706)
                        are incredibly violent in a way that I found unsettling. And that feels like a weird thing to say about horror, but it&#39;s so brutal in a realistic way that it&#39;s like, there&#39;s no humor to this. This is all just extreme realistic violence that I don&#39;t like. I don&#39;t like watching someone get brutally stabbed that way. And it feels cruel, but not in a way that&#39;s like, there&#39;s a lot of things I&#39;ve watched that I feel are really violent. And it&#39;s like, contextually I understand why</p>
                        <p>Bryan! (46:05.023)
                        Yeah, yeah, I can kind of get on board with that.</p>
                        <p>Ahem.</p>
                        <p>Dave! (46:15.746)
                        This was not justified. It felt really mean. And it just lacked, like, you know, one of the defining characteristics of the whole franchise is this kind of self-awareness. This movie has no self-awareness while still trying to uphold this kind of meta-horror identity. It&#39;s like, there&#39;s the disconnect right there.</p>
                        <p>Bryan! (46:35.667)
                        That was, okay, I had two major problems with it. And one of them was that, where at this point in its existence, it&#39;s trying to be meta about being meta. And that&#39;s just a bridge too fucking far. The other thing, yeah, the other thing that I don&#39;t care about is how fucking prevalent guns are in these slasher movies.</p>
                        <p>Dave! (46:48.909)
                        You&#39;re through looking, Glass, here, and it&#39;s not working.</p>
                        <p>Dave! (46:56.222)
                        Yeah, well, I&#39;ll tell you what, we got to the end of this movie, when the big reveal happens at the end, I said out loud, without realizing that I was speaking out loud, I said, Jesus Christ. Because the reveal at the end of this is so fucking stupid and contrived and like unrealistic in a way that feels offensive to me. Where I&#39;m just like, okay, this is too much. You gotta fucking call it.</p>
                        <p>Bryan! (47:14.003)
                        It is a s-</p>
                        <p>Bryan! (47:20.675)
                        I know I felt well the problem for me is that for the first couple of them when I was really sort of you know personally invested in them the twist legitimately caught me off guard in spite of the fact that the very the first one is so is so fucking obvious but</p>
                        <p>Dave! (47:37.738)
                        It&#39;s pretty obvious. Yep. Like you, well, that&#39;s the brilliance of it, because you&#39;re like, oh, I know who this is. And then they make you second guess it, where you&#39;re like, wait, do I though? Like, it&#39;s an effective use of red herring.</p>
                        <p>Bryan! (47:49.045)
                        I am.</p>
                        <p>Yeah, I&#39;m and I&#39;m so invested that I am willing to go along with them and just play stupid. By this point when this one came hit the end. I was like, oh shit. I was right the whole time. Like such a bummer for me.</p>
                        <p>Dave! (48:03.414)
                        Well, because you reach a point where you&#39;re like, oh, you&#39;re adding new stars to this. Well, that&#39;s the killer right there. And you know, it&#39;s a competently made film. I think moving it to New York was a good choice, but they didn&#39;t bother to use, like New York is an iconic landscape and you didn&#39;t really use much of it at all.</p>
                        <p>Bryan! (48:08.596)
                        Yeah, yeah.</p>
                        <p>Bryan! (48:14.881)
                        Oh yeah.</p>
                        <p>Bryan! (48:21.855)
                        Oh no, I&#39;m sure it was mostly Toronto.</p>
                        <p>Dave! (48:24.074)
                        Yeah, and so it&#39;s like, so that doesn&#39;t really do much for you. I think the only real saving grace of this is Jenna Ortega because I think Jenna Ortega is probably one of the best and most exciting actors I have seen in a very long time. And she really carries this because she is really, really good. And so is the, I don&#39;t know the other young woman&#39;s name. Yes, she&#39;s pretty good too. Not quite at the same level, but I think, you know, it feels a little fresh. The problem is,</p>
                        <p>Bryan! (48:38.339)
                        Oh yeah.</p>
                        <p>Bryan! (48:44.608)
                        Is it Melissa Barrera?</p>
                        <p>Dave! (48:53.314)
                        when you build a franchise around your protagonist rather than around your antagonist, you&#39;re kind of painting yourself into a corner because people are gonna age. And I think there&#39;s something, again, this screen is a big, it&#39;s a big right now because it&#39;s the way that Friday the 13th or Nightmare on Elm Street was for us, this is sort of like for millennials and younger, this is sort of like those for them. And it&#39;s just like,</p>
                        <p>Bryan! (49:02.892)
                        Mm-hmm.</p>
                        <p>Bryan! (49:19.555)
                        Yeah.</p>
                        <p>Dave! (49:21.794)
                        But even, you know, get it when you get to, you know, Friday six or Nightmare six, such as it is, we all know those movies are terrible. But to act like this one is like, I can&#39;t wait for Scream 7. Like, let these fucking people do something else, you know, to constantly demand that they like yield to us to uphold the nostalgia. Like, I don&#39;t need to see Courtney Cox come back. Courtney Cox is on a show right now that is actually pretty interesting and good. Watch that show.</p>
                        <p>Bryan! (49:29.708)
                        Oh yeah.</p>
                        <p>Bryan! (49:50.395)
                        Oh yeah, what&#39;s that one? Shining Veil? Yeah.</p>
                        <p>Dave! (49:51.714)
                        Shining Veil. It&#39;s like, is it great? No, but it&#39;s interesting. It&#39;s something different. Watch that. I don&#39;t need her to fucking do this one more time for us. Like move the fuck on, find something else. We don&#39;t need this over and over again. That is how I felt about this movie. I was just like, oh, for fuck&#39;s sake.</p>
                        <p>Bryan! (49:55.852)
                        Oh yeah.</p>
                        <p>Bryan! (50:07.019)
                        Yeah, yeah. But like, this sounds like it would have gone on the worst list for you. I personally, I think it&#39;s, I think it&#39;s just, it&#39;s okay. It&#39;s just deeply flawed.</p>
                        <p>Dave! (50:14.994)
                        Not really. It was fine.</p>
                        <p>Yeah, I just don&#39;t like all of this. Well, you know, and Nev didn&#39;t come back for this. Nev Campbell is fucking 50 years old. God help her if she doesn&#39;t wanna like, play the same character she did 25 years ago.</p>
                        <p>Bryan! (50:32.707)
                        That&#39;s the thing and man making a movie is not a quick affair. You know, she&#39;d be like fucking</p>
                        <p>Dave! (50:36.174)
                        No. And it&#39;s like we come up with like in this and I don&#39;t want to get political, but like the recent thing with Melissa Barrera and like the thing about the Israel Palestine thing. And people are just like, well, they didn&#39;t support her and blah, blah. And I&#39;m just like, you know what? Shut the fuck up. Like, it&#39;s a goddamn movie. You know, like, it&#39;s not it&#39;s not the end of the world. I don&#39;t I thought it was fine.</p>
                        <p>Bryan! (50:43.923)
                        Oh yeah.</p>
                        <p>Bryan! (50:55.16)
                        Yeah.</p>
                        <p>Dave! (51:01.382)
                        I just feel so unnecessary. Like I said, I think if anything good comes out of it, it&#39;s just more Jenna Ortega. I think I love watching her be in shit because she&#39;s just so good.</p>
                        <p>Bryan! (51:11.915)
                        Yep. So.</p>
                        <p>Dave! (51:13.11)
                        Which is why, again, you know, watch Shining Veil, watch Wednesday, skip these.</p>
                        <p>Bryan! (51:19.099)
                        Yeah, that but people won&#39;t they&#39;re probably they&#39;re probably like skipping this section. Are they done talking about scream six yet?</p>
                        <p>Dave! (51:25.59)
                        Sorry, I know I just committed some weird gay cardinal sin, but like... Sorry. The first two are great, and then they just get shittier from-</p>
                        <p>Bryan! (51:28.631)
                        Ha ha ha.</p>
                        <p>Bryan! (51:32.815)
                        Yeah. Uh, bringing up, uh, the next one. Um, not exactly a horror movie, but it&#39;s there. It&#39;s spirit. The haunted mansion. Um, I&#39;ll tell you, Disney&#39;s in a wicked rut, uh, has been for, I don&#39;t know, a while. Even</p>
                        <p>Dave! (51:46.998)
                        Is it, uh, who&#39;s in this one though? What&#39;s her face?</p>
                        <p>Bryan! (51:50.075)
                        So that is Rosario Dawson is in it. Yep, she&#39;s pretty great. Danny DeVito&#39;s in it. And I&#39;m in. That&#39;s the thing is he&#39;s in a part of his career where he&#39;s just going to play Frank Reynolds in everything he does. And I&#39;m totally, totally cool with that.</p>
                        <p>Dave! (51:52.662)
                        I don&#39;t know, I sound like her. She&#39;s alright.</p>
                        <p>Dave! (51:58.116)
                        You can&#39;t go wrong with Danny DeVito. Ever.</p>
                        <p>Dave! (52:03.79)
                        That&#39;s fine.</p>
                        <p>I mean, he spent the first half, if not more, of his career doing Louis de Palma, so...</p>
                        <p>Bryan! (52:11.675)
                        Yeah. But, um, you know, the thing is, is like, I am a, uh, a haunted mansion obsessive, uh, that whole like silly Vincent price, uh, you motherfuckers better. Uh, but yeah, um, I am, I just, I love it. And it&#39;s, it really does. It&#39;s all of the shit that I want. Like I want out of that.</p>
                        <p>Dave! (52:22.006)
                        Yeah, I&#39;m gonna dump your ashes in there. Happens all the time, apparently.</p>
                        <p>Dave! (52:33.698)
                        Who&#39;s the comedian that&#39;s in this though? The woman. She&#39;s on, god I&#39;m fucking such an old person right now. Like um.</p>
                        <p>Bryan! (52:36.236)
                        Uhhh...</p>
                        <p>Bryan! (52:42.243)
                        Oh uh shit uh god uh I&#39;ma look it up Tiffany</p>
                        <p>Dave! (52:47.702)
                        God, yes, her name is Tiffany Hattish. Tiffany Hattish. God, cut all that shit out. I think Tiffany Hattish, I don&#39;t know. In comedic roles, like I really like her as an actor. Like, I like seeing her and shit. I think she plays a certain character very, very well. And I love seeing her in everything. I have not seen this movie, but I think she&#39;s great. I welcome anything with her in it. I think this seems pretty tired. Like, didn&#39;t we just do this a couple of years ago?</p>
                        <p>Bryan! (52:49.187)
                        Tiffany Haddish.</p>
                        <p>Bryan! (53:11.969)
                        Oh yes.</p>
                        <p>Bryan! (53:16.319)
                        I know that was my first thought as I was like.</p>
                        <p>Dave! (53:17.654)
                        Like, it just feels like a weird opportunity to crank out more merch.</p>
                        <p>Bryan! (53:21.363)
                        It that is really it is it&#39;s just the by Bob Iger Disney trying to figure something out. The weirdest thing about the movie to me is that Daniel Levy isn&#39;t it he gets a screen credit up top. He is in the movie for literally seconds he has one line and then he vanishes for the rest of the movie he&#39;s great I wish. When I saw it when I saw his name in this credits I was like fucking I love that guy and then he disappears and i&#39;m like what why the fuck was his name in the credits so yeah that moving on.</p>
                        <p>Dave! (53:37.866)
                        I mean, he&#39;s great. Love Schitt&#39;s Creek.</p>
                        <p>Dave! (53:50.102)
                        This sounds like Alex is great cast in search of a movie.</p>
                        <p>Bryan! (53:53.847)
                        Basically, yeah, Jamie Lee Curtis is in it too. She&#39;s Madame Leota. It&#39;s fun, it&#39;s stupid. You can bring your kids to it. So yeah, bringing it up, Saw 10. And I know you don&#39;t give a shit about Saw. I do sort of, I&#39;ve seen them all. I&#39;ve ranked them on my letterbox. They&#39;re a mixed bag. I like the-</p>
                        <p>Dave! (54:05.494)
                        I don&#39;t, I do not care for these movies at all.</p>
                        <p>Dave! (54:19.85)
                        think it&#39;s an it was an interesting attempt to try to revive something gory from the past and it worked a little bit. But I think everyone I feel like when it comes to this franchise, people remember them in a lot different ways than they actually were.</p>
                        <p>Bryan! (54:27.563)
                        Yeah.</p>
                        <p>Bryan! (54:36.299)
                        It&#39;s kind of like how everybody&#39;s going through like a, a new metal nostalgia, like people who are now like 30 years old or like 35 are now like really nostalgic for when they were like 13 years old and like Limp Bizkit was like all the hotness. It saw really does kind of fall into that milieu. And so everybody is.</p>
                        <p>Dave! (54:56.03)
                        Oh yeah, no, it&#39;s very much like fedoras and fucking soul patches. Like it&#39;s that I the what&#39;s the torture porn. It&#39;s torture porn, like a new French extremity where I&#39;m like, look, I don&#39;t need to watch women get ripped apart.</p>
                        <p>Bryan! (55:05.451)
                        Yeah, like, uh, yeah.</p>
                        <p>Bryan! (55:13.739)
                        Yeah. But but yeah, this is yeah, this is true. But I guess</p>
                        <p>Dave! (55:15.394)
                        There&#39;s a whole genre of Italian films dedicated to that. Watch those. They&#39;re in the past where they belong. We don&#39;t need to keep recreating them.</p>
                        <p>Bryan! (55:24.011)
                        Yeah, this one was made by the guy who made my least favorite entries in the entire series, but I like Tobin Bell a good deal. Kevin, God, how do you say his fucking last name? Groot? Grooter maybe? I don&#39;t know. But he did the last few when the series was really fucking sweaty and they were given a</p>
                        <p>Dave! (55:29.026)
                        Who did it?</p>
                        <p>Dave! (55:36.246)
                        How dare you.</p>
                        <p>Dave! (55:40.618)
                        Anytime you see someone doing multiple entries in a franchise, you gotta raise an eyebrow. Lookin&#39; at you, hellhells!</p>
                        <p>Bryan! (55:46.535)
                        Yeah, yeah, but I think I think he only did like two of them, but they&#39;re just like the two most tired But like the thing is this is another one where they go back in time because the thing with saw is like Jigsaw dies in the third one and we&#39;re on the tenth one. So they keep going like further back in time Yeah</p>
                        <p>Dave! (55:57.418)
                        Jesus Christ.</p>
                        <p>Dave! (56:02.666)
                        Yeah, we should never be on the 10th one. You know what you do on the 10th one? You go to space and then it&#39;s over. Yeah, well, I hope Jigsaw&#39;s fighting fucking Freddy Krueger in that one.</p>
                        <p>Bryan! (56:07.603)
                        Hey, you know what? They&#39;re they&#39;re making an eleventh one, but uh.</p>
                        <p>Bryan! (56:13.863)
                        Pinhead, Freddy Krueger. Yeah, yeah, let&#39;s see, Brooklyn 45?</p>
                        <p>Dave! (56:16.718)
                        Because that is what happens in the eleventh one, everybody.</p>
                        <p>Dave! (56:24.494)
                        I have a lot of feelings about this one and I don&#39;t.</p>
                        <p>Bryan! (56:26.543)
                        I and I and I understand why.</p>
                        <p>Dave! (56:29.398)
                        don&#39;t know what to make of it. I think it&#39;s a great experiment that is not super.</p>
                        <p>Bryan! (56:36.439)
                        That&#39;s the thing is that&#39;s why it&#39;s on my okay as to part of the of the list. I love a locked room movie. Um, I like just about everything that Larry Fessenden&#39;s in. Great. Absolutely.</p>
                        <p>Dave! (56:44.982)
                        The cast of this movie is phenomenal. What&#39;s her name? And I wish I could remember her name. I suck at this. I wish I could remember her name. The one from Mad About You. Main character, the woman from Mad About You. I can&#39;t, well, we&#39;re terrible people. She is fantastic in this. I mean, she was also in Taking of Deborah Logan. She was great in that too. She&#39;s really good in this. I just, it&#39;s a solid story. It&#39;s really good acting, great cast.</p>
                        <p>Bryan! (56:55.207)
                        Oh yeah, yeah. I don&#39;t remember her name either. Yes we are.</p>
                        <p>Bryan! (57:13.303)
                        Very tense.</p>
                        <p>Dave! (57:13.318)
                        Which is really hard. It&#39;s hard with a locked box movie because you don&#39;t have you can&#39;t go. This is like locked room movie. You can&#39;t go anywhere</p>
                        <p>Bryan! (57:16.139)
                        Yeah, you&#39;re relying and you are relying, you&#39;re relying entirely on cast chemistry and story. And this pulls it off for the most part. I also like that it gets a lot of the period details. It kind of nails those down because that is.</p>
                        <p>Dave! (57:36.474)
                        It does and it brings me to the thing that I struggled with the most was. Well, I guess and no, I think it&#39;s admirable to try to include that. It it seems like an afterthought. It&#39;s like, OK, you don&#39;t really you&#39;re using this as a plot device. You don&#39;t really want to dig into it anymore.</p>
                        <p>Bryan! (57:40.259)
                        Could have done without the homophobia.</p>
                        <p>Bryan! (57:46.315)
                        Well, here&#39;s-</p>
                        <p>Bryan! (57:56.983)
                        That&#39;s the thing is my whole feeling on that whole character is they he yeah he could have they could have done anything else with him and the outcome would have been the same. It felt</p>
                        <p>Dave! (58:01.122)
                        He&#39;s here to be a fag.</p>
                        <p>Dave! (58:08.618)
                        Right, and he&#39;s also, he&#39;s so hyper masculine and you can do something with that. You can actually, that is an interesting premise to say, okay, why is this person in the face of war, why is he so hyper masculine and also trying to balance these two identities? I think the biggest, where this movie really goes wrong is that it&#39;s set in World War II. And I don&#39;t know why you would do that because I don&#39;t see that this shit resonates with people.</p>
                        <p>Bryan! (58:31.405)
                        Yeah.</p>
                        <p>Dave! (58:37.494)
                        Why not use Vietnam? It&#39;s, it&#39;s, I mean, it&#39;s not a one-to-one, but like for these purposes it is.</p>
                        <p>Bryan! (58:39.019)
                        That&#39;s true. I-</p>
                        <p>Bryan! (58:43.167)
                        Yeah, yeah, it&#39;s just like a creative. It&#39;s just directors decision. That&#39;s kind of what he wanted to do.</p>
                        <p>Dave! (58:47.678)
                        It&#39;s so far. Well, and I think that&#39;s the issue. Is this Ted, Ted Gagan? Is that his name? Same guy who did was at Mohawk. Interesting writer does. He&#39;s done a lot. He&#39;s written a lot more than I think he&#39;s directed. He&#39;s a good writer. But I think this is there&#39;s just so many missteps in this where it&#39;s like, I don&#39;t see this resonating with younger viewers. I mean, it doesn&#39;t resonate with me and I&#39;m not younger and.</p>
                        <p>Bryan! (58:51.456)
                        I think that&#39;s how you pronounce it.</p>
                        <p>Yep.</p>
                        <p>Bryan! (59:00.3)
                        Yeah.</p>
                        <p>Bryan! (59:10.399)
                        Yeah, you know what? It definitely feels like a movie that fits in with the whole Glass-Eye-Pix thing, which is definitely not a younger fans.</p>
                        <p>Dave! (59:15.49)
                        Yes.</p>
                        <p>No, because Larry Fessenden&#39;s been at this since about the early 90s, if not late 80s. It also feels a lot like, it has a kind of like ghost story, that Peter Straub story when the movie was in the 80s. Feels a little bit like that. Like it&#39;s such phenomenal acting. It just, it can&#39;t really seem to get itself together, but it has all the components. I wanted to love this and I didn&#39;t, but it left me thinking, and I think that is a sign</p>
                        <p>Bryan! (59:23.097)
                        Yeah. But.</p>
                        <p>Bryan! (59:29.579)
                        Yeah, for sure.</p>
                        <p>Bryan! (59:38.7)
                        That&#39;s my thing is it doesn&#39;t quite gel. Yeah.</p>
                        <p>Dave! (59:48.394)
                        of something. Like if I can&#39;t walk if I walk away from most of the shit and I&#39;m just like that&#39;s a terrible movie I will never watch again. Brooklyn 45 is a movie I will think about a lot because I think that there was something there that really could have been executed and it&#39;s just.</p>
                        <p>Bryan! (59:49.699)
                        Sure.</p>
                        <p>Bryan! (01:00:02.603)
                        before we hit my last one, I mean, for I mean, talking, speaking of movies that we can&#39;t really remember much about or that leave you kind of what do you got anything else? Okay, so yeah, I&#39;m going to close this out with Evil Dead Rise, which I have very little recollection of this movie except that.</p>
                        <p>Dave! (01:00:09.004)
                        Ugh.</p>
                        <p>Not from this section.</p>
                        <p>Dave! (01:00:17.811)
                        I remember almost nothing about that.</p>
                        <p>Now, I will say in my defense, I was probably, I had probably taken an edible before watching it. And by probably, I mean definitely.</p>
                        <p>Bryan! (01:00:29.878)
                        Yeah, that&#39;s the one of the worst things a movie can do is leave me with nothing.</p>
                        <p>Dave! (01:00:35.35)
                        Well, and I should also say upfront, I&#39;m not a big fan of the Evil Dead franchise. I mean, I like them. They&#39;re fine. They&#39;re interesting. I recognize their significance.</p>
                        <p>Bryan! (01:00:42.593)
                        I-</p>
                        <p>Yeah, well, the thing is, I put a very clear line of distinction between Evil Dead and Evil Dead 2, because even though they are the same movie, right, they&#39;re basically, they&#39;re fundamentally the same exact movie, but the Bruce Campbell funny man clown shit is really the one that I fell in love with first, and I still love, and I always felt like there was something wasted in the way that the original, like, nasty</p>
                        <p>Dave! (01:00:52.446)
                        Yeah, Eagle Dead 2 is really what kicks off the franchise.</p>
                        <p>Bryan! (01:01:14.291)
                        horror movie that they made in the early 80s was kind of forgotten about. It is, and I&#39;m going to. And so even though I don&#39;t feel particularly strongly about either of these new ones, I do like. It&#39;s surprisingly it surprised the shit out of me. I was expecting to hate it and I came away liking it. But again.</p>
                        <p>Dave! (01:01:16.714)
                        Yeah, the first one&#39;s fuckin&#39; mean. It&#39;s gross and mean.</p>
                        <p>Dave! (01:01:26.911)
                        I didn&#39;t see the remake.</p>
                        <p>Dave! (01:01:34.322)
                        My understanding of the remake, the only thing I really know about it is that even after so many times when Sam Raimi has said, I really wish I hadn&#39;t done that tree rape scene, they still do it in the remake. It is so unnecessary and gross.</p>
                        <p>Bryan! (01:01:44.931)
                        they still do it. Yeah. But yeah, the thing the thing about it is I do appreciate that there&#39;s that there&#39;s somebody out there who&#39;s taking that iteration of the movie and trying to do something about it. The parts about it about Evil Dead Rise that I do remember is the sort of really very nasty rubber appliance gore that they did. I love it.</p>
                        <p>Dave! (01:01:59.866)
                        I guess.</p>
                        <p>Dave! (01:02:09.706)
                        Yeah, no, it&#39;s glorious. Fuck. The problem is that I couldn&#39;t see anything. It&#39;s so dark. And my understanding about this is that if you saw it in the theater, it was a very different experience, which that is a problem right there. If you cannot replicate this, in especially now, if you can&#39;t replicate this, then...</p>
                        <p>Bryan! (01:02:14.388)
                        It was so dark.</p>
                        <p>Bryan! (01:02:23.541)
                        Yeah, it&#39;s-</p>
                        <p>Bryan! (01:02:28.015)
                        Because vastly more people are going to see this at home than in the movie theater, you know? Yeah.</p>
                        <p>Dave! (01:02:31.902)
                        Yeah, yeah, I mean, maybe that didn&#39;t used to be true. Maybe it won&#39;t be true in the future, but it was definitely true when it came out. I thought so. This is a continuation, right? It&#39;s not like a reboot as such. But it doesn&#39;t add much to the story.</p>
                        <p>Bryan! (01:02:43.235)
                        Correct. So like, so the evil... I think that&#39;s a big problem for it with me is they didn&#39;t, they didn&#39;t expand on it.</p>
                        <p>Dave! (01:02:53.548)
                        And it operates in an environment where it really could have, because you get it&#39;s, you know, it&#39;s doing this thing with like impoverished places and sort of</p>
                        <p>Bryan! (01:03:00.311)
                        They they do it they did they did it with it&#39;s again we&#39;re talking about Scream 6 again where they took it to the city like this is a movie that is firmly planted in the forest. And this one takes it to a city and sticks it into a kind of tenement building. Oh, yeah.</p>
                        <p>Dave! (01:03:15.862)
                        which like you do, you do cool shit with that. There&#39;s a lot of problems there that you can work with. And they kind of try to, but in a way that feels kind of goofy and sort of shoehorned in. I do, I will say I would give them bonus points for casting a trans actor and not making that trans identity a plot point. Like I thought that was, and the acting in general, I thought was good, it was fine.</p>
                        <p>Bryan! (01:03:25.867)
                        Yeah. But, uh,</p>
                        <p>Bryan! (01:03:32.235)
                        Not making a thing of it. Yeah. But yeah, it&#39;s yeah, it&#39;s just it didn&#39;t leave me with much more than just like, you know, it&#39;s okay, I guess.</p>
                        <p>Dave! (01:03:44.458)
                        Yeah, what they&#39;re doing is they&#39;re recreating all the like creepy eyes through the trap door scenes and that&#39;s about where it all stops.</p>
                        <p>Bryan! (01:03:52.063)
                        Yeah. So let&#39;s move on to stuff that we actually did like this is our Oh, yes, numerous ones. So I&#39;m gonna start it out with Renfield. I loved it. Loved it to death delivered on its</p>
                        <p>Dave! (01:03:58.395)
                        Oh, and there were some of those.</p>
                        <p>Dave! (01:04:04.83)
                        Loved it. Now, this is not ingenious. It is not original really at all, but it is super fun.</p>
                        <p>Bryan! (01:04:11.795)
                        It&#39;s actually running on the same sort of silly, ultra-gory, Evil Dead 2 kind of line.</p>
                        <p>Dave! (01:04:17.49)
                        And it is probably the most violent movie I have seen in years. Like, it is disgustingly gross.</p>
                        <p>Bryan! (01:04:24.484)
                        Yeah, yeah, it really it delivers on its promise of silly bullshit.</p>
                        <p>Dave! (01:04:27.094)
                        But you know, really it&#39;s a very, again, it&#39;s that earnestness where it&#39;s like, this is so charming because you&#39;ve got a great fucking cast. Like if Nicholas Cage was ever made for anything, it&#39;s this.</p>
                        <p>Bryan! (01:04:32.012)
                        Yeah.</p>
                        <p>Bryan! (01:04:37.387)
                        Oh yeah, and this is a movie that was made specifically for him to lean into that sort of extreme cage era that we&#39;re in right now. And I do not object.</p>
                        <p>Dave! (01:04:45.03)
                        Like he may as well have been doing kung fu moves. Like I thought Nicholas Holt was great. I even thought Aquafina was good and I don&#39;t like her as an actor. But I thought she was pretty good. Was it Ben Schwartz? Is he the, he&#39;s fantastic in this as well. And he is Jean Ralphiot.</p>
                        <p>Bryan! (01:04:50.115)
                        Mm-hmm.</p>
                        <p>Bryan! (01:04:54.868)
                        I don&#39;t get her appeal at all, but I thought she was funny.</p>
                        <p>Yep. He was the bad guy.</p>
                        <p>Bryan! (01:05:04.551)
                        I was, yep, I was surprised. I would do the whole singing thing, but I can&#39;t do it. But yeah, I was not expecting him to be the bad guy and he&#39;s fucking hilarious.</p>
                        <p>Dave! (01:05:15.61)
                        No, I think everybody was really great. It was so well cast, it was so well made. It&#39;s a really good example about a take an old story and make it feel fresh, which is like for better or worse, we&#39;re never gonna stop doing that. And like, I think if you&#39;re gonna do it, look at this as a model because it&#39;s fun. It takes a story that is very serious and makes it feel fresh and engaging and kind of like fun to watch.</p>
                        <p>Bryan! (01:05:28.259)
                        Mm-hmm.</p>
                        <p>Bryan! (01:05:41.015)
                        Yep. And I&#39;ll tell you what I&#39;ve really, really loved about it most was the Dracula remake scenes where the cast of their cast in the original, the original scenes, fucking delightful. Yep. So yeah, after that, totally killer.</p>
                        <p>Dave! (01:05:54.678)
                        This is one that could have gone way the other direction. And the fact it is the cast alone that keeps it from doing.</p>
                        <p>Bryan! (01:06:01.683)
                        So this is the thing, is what you had mentioned earlier. I, if I had, I see, I have not seen Happy Death Day. I have not seen It&#39;s a Wonderful Life. I think that if I had seen either of those, I think that if I&#39;d seen either of those before this one, I might&#39;ve been a little bit more cynical, because as I&#39;m finding...</p>
                        <p>Dave! (01:06:09.762)
                        Happy Duththing is a good movie.</p>
                        <p>Bryan! (01:06:18.811)
                        this whole like teens go back in time, teens go to another dimension, teens you know and then but it&#39;s also a slasher movie like this is back to the future it&#39;s a wonderful knife is obviously it&#39;s a wonderful life happy death day is groundhog day like they&#39;re all kind of doing these tropes i think that if i had gotten to this</p>
                        <p>Dave! (01:06:36.246)
                        Which if you&#39;ve never seen them before probably feels fun, but if you have seen them before over and over again, it&#39;s like, oh, this again. Okay.</p>
                        <p>Bryan! (01:06:39.704)
                        Yeah.</p>
                        <p>Bryan! (01:06:44.587)
                        Yeah, but I thought this one was fun as hell. I think.</p>
                        <p>Dave! (01:06:48.158)
                        It&#39;s the I think it&#39;s the cast. I think it is 100 percent the cast that really elevates us because the chemistry between she is phenomenal. And it&#39;s like she&#39;s good in most things that I&#39;ve seen her in. But she is really because she is she is so charming. She&#39;s so cute. And like you just you kind of love her in everything.</p>
                        <p>Bryan! (01:06:51.075)
                        100%. Kirna Chipka is awesome. Yeah.</p>
                        <p>Bryan! (01:07:04.427)
                        Oh yeah, I&#39;ll tell you what, I loved Sabrina. And I&#39;ll tell you, she was one of the most fascinating characters in Mad Men because she was fucking Don Draper&#39;s like one weak spot. And so she was like a very like interesting characteristic of that whole thing. And she&#39;s just like, whatever she does, I&#39;m gonna fucking watch because she&#39;s.</p>
                        <p>Dave! (01:07:08.404)
                        Mm-hmm.</p>
                        <p>Dave! (01:07:25.354)
                        And this, I mean, this could have gone the other direction so easily because it could have been very dismissive in that way of like, oh, you used to be this way. And it&#39;s like, oh, fuck you. But like, she does it in a way that&#39;s very much like, okay, I don&#39;t have time for that.</p>
                        <p>Bryan! (01:07:34.419)
                        Oh yeah. Yeah, because the thing is, is you&#39;re, you&#39;re walking a tight rope when you make a movie about modern teenagers reacting to the way people used to be. But it, I don&#39;t know, it works here. It&#39;s, it&#39;s fine.</p>
                        <p>Dave! (01:07:48.83)
                        And it was also like, by the way they used to be not that long ago. And I think like, I think her friend, I can&#39;t remember the girl&#39;s name, the character in the present day who kind of like helps her. She&#39;s fantastic as well and their chemistry is so good. Like everybody just, it all works together.</p>
                        <p>Bryan! (01:07:52.741)
                        Nop.</p>
                        <p>Bryan! (01:07:58.531)
                        The girl. Oh, yeah, yeah.</p>
                        <p>Bryan! (01:08:03.919)
                        Uh huh. Oh, even the fact that she&#39;s just like, Oh yeah, by the way, my science project is a is a time machine, whatever.</p>
                        <p>Dave! (01:08:11.914)
                        Yep. Like it&#39;s really good. It just it has that sort of like don&#39;t ask too many questions. Just fucking go with it. And you&#39;re going to have a good time. And it really does. Like it&#39;s kind of a predictable story. By the time you get to the end, you&#39;re like, oh, well, I didn&#39;t quite see this coming, but it feels very now of the moment. Like without this cast, this would have been really bad. But because of who&#39;s in it, I mean, everybody like Julie Bowen is fantastic. Everybody&#39;s so good. But there&#39;s so many threads where it&#39;s like, do not pull on these. The whole movie comes.</p>
                        <p>Bryan! (01:08:31.339)
                        Yep, it would have.</p>
                        <p>Bryan! (01:08:37.166)
                        Hmm.</p>
                        <p>Bryan! (01:08:40.599)
                        Yeah. So let&#39;s see the next one. I don&#39;t know if you&#39;d seen this. Did you ever get around to when evil lurks?</p>
                        <p>Dave! (01:08:48.07)
                        No, my understanding is that it has a pretty awful dog death in it.</p>
                        <p>Bryan! (01:08:52.763)
                        Um, well, in the sense that the dog kills a child, but yeah, it&#39;s</p>
                        <p>Dave! (01:08:56.914)
                        Oh, no, I&#39;m fine with that. But I have to check horror movies. I check them on does the dog die because I can&#39;t I can&#39;t do violent animal death or violent dog death. I won&#39;t do them. And so I haven&#39;t watched this one. This is Argentinian. Again, there&#39;s another example of like foreign horrors doing it better.</p>
                        <p>Bryan! (01:09:11.691)
                        Yeah. Yep.</p>
                        <p>Bryan! (01:09:16.863)
                        Yep. It&#39;s the thing that really that I fucking love about it more than more than anything else is it does a thing that I very see I see so seldom. And it is the movie has a very specific very particular internal mythology happening it happens it&#39;s taking place in a world where demonic possession is a real thing that happens to people and if you do not deal with it in a very specific way then.</p>
                        <p>the possessed people like eventually sort of erupt and wipe out entire parts of the world. So there is this weird crisis that is never really fleshed out. It&#39;s just kind of thrust at you as soon as the movie starts and you kind of ride along with it. And at no point does it ever feel obligated to explain itself. And so even...</p>
                        <p>Dave! (01:10:05.406)
                        Is it metaphorical? Like is it this sort of idea of like there is a world full of evil and if we don&#39;t do something about it will destroy us all?</p>
                        <p>Bryan! (01:10:12.891)
                        Maybe, but like this, it does not ever hint at like a sort of larger point or anything like that. It&#39;s just a horror movie. It&#39;s got a whole thing going on. And it&#39;s the sort of thing that was like, if they don&#39;t make a sequel, I&#39;m going to fucking write it myself. Because like it is such a fascinating little world that this...</p>
                        <p>Dave! (01:10:20.552)
                        OK. I like a contained story.</p>
                        <p>Dave! (01:10:33.386)
                        I know it got a lot of praise just sort of generally speaking. And again, I love, I&#39;m a big fan. I&#39;m a, oh, I don&#39;t want to say I&#39;m a big fan. I&#39;m a fan and a heading towards big fan of Latin American horror. I think there&#39;s, it&#39;s such fertile ground. Unfortunately, it&#39;s, you know, like most places, but Latin America is a very troubling and troubled history and a lot of recent trauma to work from.</p>
                        <p>Bryan! (01:10:38.216)
                        It is poor man.</p>
                        <p>Bryan! (01:10:47.308)
                        Mm-hmm.</p>
                        <p>Bryan! (01:11:00.359)
                        Mm-hmm.</p>
                        <p>Dave! (01:11:00.966)
                        I think that&#39;s why we get a lot of this. And you get a lot of shit that&#39;s like stumbling around the dark, a lot of really low budget stuff. But every now and then these things come out and it&#39;s like, wow. Like, did you see La Llorona? Not the shitty James Wan, James Wan verse, but the other one. That is an incredible movie. It was so fucking good. And when I finished watching it, I was like, Jesus Christ, I feel terrible.</p>
                        <p>Bryan! (01:11:09.876)
                        It is.</p>
                        <p>Bryan! (01:11:16.397)
                        No, no, I&#39;ve not.</p>
                        <p>Bryan! (01:11:24.691)
                        It&#39;s, it is the James Wan one that I, is that I kept confusing it with. So I deliberately have avoided anything with La Llorona. I know that, I know that Uike Horror have talked about those movies a little bit.</p>
                        <p>Dave! (01:11:32.862)
                        It is- it&#39;s a phenomenal-</p>
                        <p>Dave! (01:11:38.466)
                        Yeah, they do it on that. They do it on Khoror. And I think they did this one as well.</p>
                        <p>Bryan! (01:11:43.519)
                        Yeah, but oh yeah, they did this one just recently. This one was kind of</p>
                        <p>Dave! (01:11:45.63)
                        And if y&#39;all aren&#39;t listening to that show, you are missing out. They are really fucking good.</p>
                        <p>Bryan! (01:11:48.927)
                        It&#39;s the fucking best. Yeah, but like this is, this was a big hype one. It kind of like landed, I believe it&#39;s Sitges or maybe it was Toronto, but it landed basically at one of the film festivals and was like a fucking farting church. Like everybody was all just stopped what they were doing.</p>
                        <p>Dave! (01:12:06.494)
                        I love that shit. I love when people are like, hey, here&#39;s a movie that is going to just fucking bowl you over in one way or another. You&#39;re gonna react to it.</p>
                        <p>Bryan! (01:12:13.771)
                        because it is very, very nasty and very dark. So bear that in mind.</p>
                        <p>Dave! (01:12:21.582)
                        I love that shit though. Like, as long as, my only, the only thing that put me off was like, if it has awful animal death, I really struggle with that because I just don&#39;t want to see it. And I think it&#39;s cheap and lazy.</p>
                        <p>Bryan! (01:12:30.111)
                        I gotta go, I gotta go back. I mean, the dog dies, but it&#39;s not like, I don&#39;t remember, I don&#39;t believe it happens on screen. But the thing involving the dog is it does that thing like when a dog picks up a doll and like whips it around.</p>
                        <p>Dave! (01:12:43.166)
                        Look, you can mow down a fucking field of babies. I don&#39;t give a shit about that. Humans are awful. I don&#39;t care about them.</p>
                        <p>Bryan! (01:12:47.239)
                        Yeah. It does that thing where like, yeah, it picks up a doll and like whips it around. It does that with a with a human child. And it was I was like, holy shit was not expecting that to happen. So, yeah, it&#39;s a it&#39;s a fucked up movie. It&#39;s got a lot in common with the whole Evil Dead thing, though. It&#39;s very nice. It&#39;s definitely not like going like, hey, you know, Evil Dead, right? Like we&#39;re doing that, too. It&#39;s definitely it&#39;s very original. It&#39;s doing its own thing. It&#39;s just similar in tone. So it&#39;s pretty heavy.</p>
                        <p>the way and it&#39;s one of those movies that sort of like really commits to its whole like gloom and doom premise so yeah it goes all the way through uh</p>
                        <p>Dave! (01:13:25.016)
                        Onto the last one, which for me was the horror film of the year.</p>
                        <p>Bryan! (01:13:29.183)
                        Oh yeah, 100% all the way. Speaking of committing to its premise, fucking talk to me. Loved it. I can&#39;t, and the thing was, is, and I feel like such a fucking snob about this, but it had so much going against it when I went in. It&#39;s got the fucking 824 logo on it, which I just, I cannot, I cannot fucking cotton to. I fucking hate that stuff. It was made by YouTubers, which.</p>
                        <p>Dave! (01:13:46.326)
                        Well, yeah. Nope.</p>
                        <p>Dave! (01:13:54.25)
                        Also, could have been a huge problem because... talentless? Not these guys though.</p>
                        <p>Bryan! (01:14:00.328)
                        No. And also, lastly, it&#39;s modern. And I, you know, it&#39;s very fair to say that we are largely dismissive of modern horror movies. Not this one. I...</p>
                        <p>Dave! (01:14:09.834)
                        And I will readily admit, most of that comes from like, we&#39;re just too old. Like, this shit is not for me. I mean, and I watch a lot of stuff where I&#39;m like, I don&#39;t get this. And I think it was probably around, like once I reached 40, I think that was when I started to be like, oh, it&#39;s not that it&#39;s bad. It&#39;s just that it&#39;s not for me. Like, I&#39;m not the market here.</p>
                        <p>Bryan! (01:14:27.927)
                        Yeah, yeah, that&#39;s the thing is when I used to, I definitely, I used to be really shitty about that, where I&#39;d be like, man, fuck this, this is, this is the stupidest shit I&#39;ve ever seen. And like, this is definitely not for me. But that last part, not for me, was something that I eventually kind of was like, oh, okay, well, I&#39;m talking about a very subjective experience as though it&#39;s that is. Yeah. Oh, yeah, the world has moved on without me. Yeah, definitely, definitely. But this, this one had me.</p>
                        <p>Dave! (01:14:45.758)
                        And because that&#39;s a hard thing to accept too, when you&#39;re like, oh right, everyone&#39;s moved on. Ha ha ha.</p>
                        <p>Bryan! (01:14:57.109)
                        right out of the gate.</p>
                        <p>Dave! (01:14:57.194)
                        This is another one too that is, I think, really saved. Not even saved, because it&#39;s a good premise. But this is a movie that is propped up by really good acting from young people, which is rare.</p>
                        <p>Bryan! (01:15:06.731)
                        Yep. The thing that also is that I could tell was they had probably been working this these this script in drafts for years because it is very tight and it is it&#39;s extremely original. Like I&#39;ve never seen a movie that does this.</p>
                        <p>Dave! (01:15:14.802)
                        Yeah.</p>
                        <p>Dave! (01:15:23.274)
                        It&#39;s a hard sell too because it&#39;s like, this is an interesting, I don&#39;t wanna say nuanced, but it&#39;s a complicated story.</p>
                        <p>Bryan! (01:15:27.591)
                        It&#39;s another one of those, it&#39;s another one of those. Yeah. It&#39;s another one of those ones where you just kind of have to go with it because like, what is this hand thing? They never really give it to you. You just have to accept that. You just have to accept that it&#39;s there. So.</p>
                        <p>Dave! (01:15:38.56)
                        And I love that.</p>
                        <p>And you know, and I want to watch this. It made me think of something that when we did Watcher in the Woods and we got to the end and Terry said something to me that was like, I don&#39;t know how to paraphrase, but just basically just like, oh, I don&#39;t you know, I didn&#39;t need the movie to kind of lay it all out. And I realized the difference between that and this is that.</p>
                        <p>Bryan! (01:15:58.286)
                        Mm-hmm.</p>
                        <p>Dave! (01:16:02.678)
                        This, they&#39;re not laying it out for you here. They&#39;re basically just like, I don&#39;t know, come up with whatever explanation you want. It doesn&#39;t really matter. Whereas in that movie, it&#39;s like, no, that&#39;s just bad writing. Watcher in the Woods is bad writing. This is good writing that lets your mind take over.</p>
                        <p>Bryan! (01:16:12.599)
                        That&#39;s the thing is Watcher in the Woods. Yeah, Watcher in the Woods is definitely very shoddy and slapdash. This one is written in such a way that. No matter, no matter, they never tell you what this hand is, but it doesn&#39;t matter for whatever reason. I was just ready to accept that. I loved it. Yeah.</p>
                        <p>Dave! (01:16:30.258)
                        Exactly. And that&#39;s great. I love it. It doesn&#39;t matter. I think that is such a great. And when you can use that, well, it&#39;s rare that people can really pull it off. But when they do, it&#39;s so fucking good because this movie, the premise alone makes me uncomfortable. I don&#39;t like I make no secret of the fact that I don&#39;t like teenagers. I don&#39;t like children in general. OK, I don&#39;t like people, but yeah.</p>
                        <p>Bryan! (01:16:53.2)
                        Up yours, children.</p>
                        <p>Dave! (01:16:58.242)
                        But I don&#39;t really like, I don&#39;t like them and I don&#39;t like watching them in movies. Generally because teenagers are inherently selfish and there&#39;s a reason for that and I get it. But you watch them make bad choices and I hate watching them make bad choices. And this is a movie that really exploits that because you are going to watch one particular teenager make incredibly selfish choices, one after another after another, and you&#39;re gonna hate her the entire time.</p>
                        <p>Bryan! (01:17:07.491)
                        Oh yeah.</p>
                        <p>Bryan! (01:17:27.523)
                        Mm.</p>
                        <p>Dave! (01:17:27.97)
                        but they carry that thread from the very beginning to the very end, and they carry the metaphor from the very beginning to the very end. Because the moment when she stops, I&#39;m trying not to spoil things, but like the moment where she stops with the dying kangaroo in the room, and like the dying kangaroo becomes a metaphor that is carried through this entire movie to the very end, you&#39;re like, oh, not only does this girl make shitty choices one after another because she&#39;s a selfish asshole.</p>
                        <p>Bryan! (01:17:39.348)
                        Yeah, yeah.</p>
                        <p>Dave! (01:17:56.802)
                        She also cannot do the right thing ever. And in the end, it turns out like the right thing is actually the wrong thing, but like it&#39;s still, she can&#39;t even do it then. And it&#39;s like, it turns out to be the sort of saving grace. It was so, it was so good. It&#39;s smart, it&#39;s tight, it&#39;s fantastic.</p>
                        <p>Bryan! (01:17:59.179)
                        Yep. I, that&#39;s the thing.</p>
                        <p>Bryan! (01:18:06.777)
                        No.</p>
                        <p>Bryan! (01:18:10.283)
                        It commits to its premise. Yep. And the thing that I really do, I do appreciate about it is that it does not wimp out in the end. I, you know, I don&#39;t need it. I don&#39;t, I definitely don&#39;t want every, every horror movie I watched to be a fucking gloom and doom fest straight to the ending. But every now and then when it happens and it happens</p>
                        <p>Dave! (01:18:29.81)
                        Sometimes you need Ben to be killed. You need Ben to be shot in the head through the window at the end. Like you need it to happen. You need you need a bad ending because it&#39;s like, I like that shit. It&#39;s like I like when you don&#39;t, you know, nobody gets away clean. I thought there was a bit like at the very end, it feels a little producer note when it&#39;s like, you know, you got to have her go into the void type of thing. I&#39;m like, I don&#39;t really need that. Like you&#39;re setting it up for a sequel, which I don&#39;t like. But whatever, I&#39;d take it.</p>
                        <p>Bryan! (01:18:35.339)
                        Yeah. And so, yeah. Yep. And I love it.</p>
                        <p>Bryan! (01:18:53.808)
                        Yeah.</p>
                        <p>Bryan! (01:18:58.975)
                        Whatever. Yeah. I suppose. Okay.</p>
                        <p>Dave! (01:19:00.638)
                        I&#39;m gonna give you an honorable mention though. You have not seen, but you fucking need to see. And it&#39;s not quite a horror movie. I&#39;m gonna tell you about Godzilla minus one.</p>
                        <p>Bryan! (01:19:09.599)
                        Ooh, I know, I know, I fucking know. I&#39;ve gotta see it, I have not yet. I definitely will.</p>
                        <p>Dave! (01:19:13.378)
                        I mean, this movie is, I love that it is breaking records in the US because let&#39;s be honest, Americans don&#39;t watch foreign movies for shit for the most part. And to be fair, the Toho lineage is a little silly, a little bit dopey. And then when they kind of rebooted the franchise, it&#39;s so culturally specific that if you don&#39;t have that whole kind of knowledge of the history of the franchise,</p>
                        <p>Bryan! (01:19:21.655)
                        Right, right.</p>
                        <p>Bryan! (01:19:35.8)
                        Mm-hmm.</p>
                        <p>Dave! (01:19:42.37)
                        shit like Shin Godzilla feels it just feels out of reach. It&#39;s not something you can connect with. And, you know, and I like Shin Godzilla, I think. I haven&#39;t seen the other. I haven&#39;t seen like Shin Kamen Rider. I haven&#39;t seen any of that, but like. I would like to see them and I will watch them when I have time, but like I haven&#39;t.</p>
                        <p>Bryan! (01:19:46.155)
                        It feels disconnected a little bit, yeah. I love it, I think it&#39;s fantastic.</p>
                        <p>Bryan! (01:19:53.692)
                        Shin Ultraman is great.</p>
                        <p>Bryan! (01:19:59.384)
                        I will watch anything Hideaki Anno makes. He has secured me as a lifelong fan with Neon Genesis Evangelion, so yeah.</p>
                        <p>Dave! (01:20:07.958)
                        This this movie, though, is they&#39;re doing something so new and so different that you I have not seen come from Japan. I don&#39;t think ever really. But like I see a lot of people being like, well, it&#39;s the greatest Godzilla movie ever. The greatest kaiju movie. I don&#39;t agree with that. I think Godzilla is the greatest Godzilla movie. For a lot of different reasons, I think this is.</p>
                        <p>Bryan! (01:20:29.007)
                        Hehehe</p>
                        <p>Not, not, not destroy all monsters.</p>
                        <p>Dave! (01:20:34.854)
                        I, that is a, that is the Silent Night, Deadly Night 2 of kaiju movies. Will I watch it? Absolutely. Do I love the score? I sure do. I do not think this is the best. I think it is the second best because this is essentially, again, this is sort of like, how do you reboot something? How do you remake something? This is how you do it because what Godzilla minus one essentially is, is a remake of the original.</p>
                        <p>Bryan! (01:20:40.093)
                        Hahaha!</p>
                        <p>Bryan! (01:20:44.473)
                        Oh, God, yes.</p>
                        <p>Dave! (01:21:04.562)
                        and it is done through such a modern lens where the original is a kind of rumination on what happened after the war. And they don&#39;t really say, they never say anything about Americans. They don&#39;t directly address it, but you know what they&#39;re talking about. This is a way of processing the bombs. And it is done in this very dark.</p>
                        <p>Bryan! (01:21:21.108)
                        Oh sure.</p>
                        <p>Bryan! (01:21:25.827)
                        Oh, yeah, the thing that I think people are surprised by when they catch up to Godzilla is how fucking bleak that movie is.</p>
                        <p>Dave! (01:21:33.398)
                        It is, and it is, I think it&#39;s also a very beautiful movie. I think there are scenes in that movie that are so stunningly gorgeous. The ending of Godzilla is one of my, probably top five favorite scenes ever, the underwater moment. And this is done, they do this with homage, they do, in this new one, there&#39;s homage, they do like shot for shot remake, like scene recreations of like Godzilla with the train in his mouth.</p>
                        <p>Bryan! (01:21:39.005)
                        Hmm.</p>
                        <p>Dave! (01:22:00.05)
                        and shit like that. And it&#39;s really, it&#39;s like real cool. Like if you know, you know, it&#39;s that kind of thing where you&#39;re like, oh, I&#39;ve seen this before and it looks really fucking cool. But otherwise it is, they kind of flipped the script a little bit and it becomes a thing of what responsibility do we bear for what happened? And it is this question of why are we all so okay with the fact that the government seems to think that we are expendable people, that lives are expendable in the name of</p>
                        <p>Bryan! (01:22:27.106)
                        Yeah.</p>
                        <p>Dave! (01:22:29.322)
                        And it really brings up the question of like, this question of honor or something about kamikaze, because the main character is a kamikaze pilot who refuses or sort of fails to fulfill his destiny and carries lots of shame because of that. And that becomes a thing they carry through to the end. And it really, it is confrontational in a way that I have never seen in a Japanese movie before. And I think it is really, really impressive because they really pull it off. Like,</p>
                        <p>It could go wrong at any minute because you&#39;re also balancing it with like, we also want to see a giant lizard come out of the water and eat a boat. And you know what? You get that too. And I&#39;ve seen a lot of people bitching about like, why aren&#39;t there, Godzilla&#39;s hardly in it. And it&#39;s like, yeah, the original wasn&#39;t really about Godzilla either because Godzilla is a metaphor.</p>
                        <p>Bryan! (01:23:05.343)
                        Yeah.</p>
                        <p>Bryan! (01:23:14.571)
                        right? I think that&#39;s the thing is people gotta remember most of the Toho Godzilla&#39;s are 90 minutes to two hours and maybe 20 minutes of it is Godzilla fighting. So yeah. Alright.</p>
                        <p>Dave! (01:23:29.994)
                        Yeah, but it is a phenomenal, like I, this was another one, like I don&#39;t go to the movies a lot. Like I said, I didn&#39;t go to the movies, I hadn&#39;t been in 10 years until I went to see Scream. I have been to the movies more in the last year than I have in the last, I don&#39;t know, 20 years probably. I went to see this, IMAX theater, it was fucking great. It was so awesome, it&#39;s big, it&#39;s great.</p>
                        <p>Bryan! (01:23:48.675)
                        That&#39;s awesome. It&#39;s, I think, I think it&#39;s still, I think it&#39;s still playing up the street for me. It&#39;s just, I have nobody to go to it with. So, yeah.</p>
                        <p>Dave! (01:23:55.67)
                        Go alone. It is an absorbing movie. You know, it starts to drag a little bit here and there. There&#39;s things where it&#39;s like, yeah, you could have cut this out. You don&#39;t need to, but if you wanted to for length, you could have. And it was such a phenomenal movie. I loved it. I think it&#39;s, it&#39;s again, it&#39;s another one of those moments where it&#39;s like, if you want to reboot some shit, here&#39;s how you do it. Do this.</p>
                        <p>Bryan! (01:24:14.539)
                        Yeah, yeah. Not bad. 2023. We hardly knew you. But yeah, a few genuine hits and a lot of fucking middling garbage. So sacrifice. Fucking skin and meringue. Let us never speak of it again. Oh, God. All right, people. Let us let us know. You know,</p>
                        <p>Dave! (01:24:24.482)
                        That&#39;s where she came from the woods. Thanks, Tooby. You did it again, you old bastard.</p>
                        <p>Dave! (01:24:33.622)
                        Yep, Skin of Marenka, 2B original.</p>
                        <p>Dave! (01:24:41.11)
                        Yeah, what fucking movies did you all like see?</p>
                        <p>Bryan! (01:24:44.639)
                        Yeah, you know how to get in touch with us. You know, drop us a line on Instagram. You know, I&#39;ll put a Q&amp;A in this show. You know, do it, you know, send us an email. Send us a fucking YouTube comment, whatever. We definitely love hearing from you guys. So, you know, it&#39;s been an awesome year and year two is gonna be even better. So thanks for listening and keep on listening. So yeah, we&#39;ll be back in a week with Black Christmas.</p>
                        <p>Dave! (01:25:04.302)
                        in.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/black-christmas">Next Episode</Link></li>
                        <li><Link to="/episodes/silent-night-deadly-night">Next Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)